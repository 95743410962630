@media only screen and (min-width: 1024px){

  .ArtistModalContainer_wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index:30;
  }

  
.ArtistModalContainer_background_white {
  width: 480px;
  height: 600px;
  background-color: white;
  position: relative;
  margin: 120px auto;
  padding: 20px;
  background: #fff;
  border-radius: 15px;    
}


.ArtistModalContainer_content{
 justify-content: center;
 align-items: center;
 display: flex;
 flex-direction: column;
 height: 580px;
}

.ArtistModalContainer_title{
  width: 400px;
  padding-top: 15px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.ArtistModalContainer_modalContents {
  margin: 0 auto;
  padding: 10px 20px 32px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ArtistModelContainer_textarea{
  width: 400px; 
  height: 220px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  resize: none;
}



}



      


   