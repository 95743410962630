

  .AlertModalContainer_wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index:999;

}
.AlertModalContainer_background_white {
  width: 350px;
  height: 150px;
  background-color: white;
  position: relative;
  justify-content: center;
  /* margin: 80px auto; */
  margin-left: auto;

  margin-top: 120px;
  padding: 20px;
  border-radius: 15px; 
  animation: alertAnimation 1s ease forwards;

/* justify-content: center;
align-items: center; */
}
@keyframes alertAnimation {
  0% {
    transform: translateX(350px);
    
}

100% {
     transform: translateX(0px);
  
}
}

.alert-header{
  font-size: 24px;
  text-align: center;
}
.alert-body{
  margin-top: 10px;
  text-align: center;
  height: 40px;
}










 