@media only screen and (min-width: 1024px){
    #MyHome_sidebar_wrapper{ position: relative;  width: 14%;  }
    #MyHome_sidebar_wrapper #navigation { position: relative; overflow: hidden;  }
    #MyHome_sidebar_wrapper #navigation .MyHome_list { position: relative; overflow: hidden;     }
    #MyHome_sidebar_wrapper #navigation .MyHome_list li { float: bottom;   text-decoration: none;    }
    #MyHome_sidebar_wrapper #navigation .MyHome_list li a:link, 
    #MyHome_sidebar_wrapper #navigation .MyHome_list li a:visited {  padding: 10px 0 10px 36px;   font-weight: 500;   text-decoration: none; display: block;  height: 54px;  line-height: 50px; color: #3f3f3f; font-size: 14px; letter-spacing: -0.75pt; text-align: left;  flex-direction: column; }
    #MyHome_sidebar_wrapper #navigation .MyHome_list li a:hover { background: #c2c2c2ad; color: rgb(46, 46, 46); font-weight:600; }
  
    .MyHome_sidebar{
        
        position: relative;
        display: inline-block;
        background:#fff;
        border-right: 1px dashed #58585875;
        /* border-radius: 15px; */
        /* box-shadow:  0 12px 13px rgba(0,0,0,0.16), 0 12px 13px rgba(0,0,0,0.16); */
        /* border-right: 1px solid #585858; */
        transition: all 0.5s;
       
    }
  
}




   

 

   
  
 