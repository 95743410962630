* {
  font-family: "Spoqa Han Sans Neo", "sans-serif";
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
}
