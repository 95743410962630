

/* ///////////////////////////////////전체 테이블///////////////////////////////////////// */
@media only screen and (min-width: 1367px) {
    .Hardisest_table{
          
        width: 80%;
        border-collapse: collapse;
        text-align: center;
        margin-top: 20px;
        margin: auto;
        table-layout: fixed;
        cursor:default;
        outline:none;
        }    
    
        .Hardisest_table_thead{
            width: 80%;
            /* height: 70px; */
            background-color: rgb(255, 255, 255);
            border-bottom: 1px solid #97979754;
            border-top: 1px solid #97979754;
        }

        
   
    /* ///////////////////////////////////T-HEAD///////////////////////////////////////// */

       
    .Hardisest_tableHead_index{    width: 80px; font-size: 15px;}
    .Hardisest_tableHead_songname{ width: 620px;    padding-left: 0px;text-align: center; font-size: 15px;}
    .Hardisest_tableHead_genres_edit{   width: 190px; padding-left: 10px; padding-right: 10px; font-size: 15px;}
    .Hardisest_tableHead_price{   width: 180px; padding-left: 10px; padding-right: 10px;  text-align: center; font-size: 15px;} 
    .Hardisest_tableHead_download{   width: 120px; padding-left: 0px; text-align: center; font-size: 15px;}
    .Hardisest_tableHead_view{   width: 120px; padding-left: 0px; text-align: center; font-size: 15px;}

    .Hardisest_tableHead_favorite{width: 80px; padding-left: 0px; padding-right: 0px; text-align: center; font-size: 15px; }
    .Hardisest_tableHead_damgi{   width: 50px; padding-left: 0px; font-size: 15px;}
    .Hardisest_tableHead_rating{   width: 180px; padding-left: 0px;}
    
    
    
    /* ///////////////////////////////////T-BODY///////////////////////////////////////// */
    
    .Hardisest_tbody_table{
        border-bottom: 1px solid #97979754;
        text-align: center;
        outline:none;
        height: 80px;
    }

    .Hardisest_tbody_song_artist{ display:flex; flex-direction: row; cursor: pointer;  position:relative;    text-overflow:ellipsis;   word-break:break-all;  overflow:hidden;  text-align: left;   line-height: 24px; }   

    .Hardisest_tbody_table :hover{background-color:rgb(241, 241, 241);  cursor: pointer;}
    .Hardisest_tbody_index{  font-size: 28px; font-family:Arial, Helvetica, sans-serif; font-weight: 600; color:rgb(138, 138, 138); font-style: italic;}
    .Hardisest_tbody_image{
        cursor: pointer;
        min-width: 75px;
        max-width:75px;
        min-height: 75px;
        max-height: 75px;
        object-fit: cover;
        align-items: center;
        transition: 0.3s ease;
        position: relative;
        padding: 8px;
    }
   
    .Hardisest_song_songName {
        flex-direction: column;
        display: flex;
        text-align: left;
        justify-content: center;
        line-height: 21px;
        font-size: 15px;
        color: #666666;
        overflow:hidden;
		white-space : nowrap;
		text-overflow: ellipsis;
        margin-left: 10px;
    }
    
    .Hardisest_song_artistname{margin-top: 2px;   color: #666666;   position: relative;  font-size: 12px;}
    .Hardisest_tbody_genres_edit{    color: #666666;  width: 180px;  padding-left: 10px;  padding-right: 10px;  font-size: 15px; text-align: center; } 
    .Hardisest_tbody_price{
        text-align: center;
        color: #666666;
        width: 180px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 15px;
    }
    .Hardisest_tbody_view{  color: #666666; padding-right: 8px; text-align: center;  font-size: 15px; align-items: center; justify-content: center; }
    .Hardisest_tbody_upgrade{  color: #666666; padding-right: 8px; text-align: center;  font-size: 15px; align-items: center; justify-content: center; }
    .Hardisest_tbody_favorite{   color: #666666; width: 80px; text-align: center; font-size: 15px;}
    .Hardisest_tbody_rating{
        font-size: 9px;
        position: relative;
        color: #b3b3b3;
        justify-content: center;
        align-items: center;
   

    }
    .Hardisest_tbody_rating_star{   justify-content: center;
        align-items: center;}
    .Hardisest_tbody_rating_star_icon{ padding-top: 0px;  margin-top:20px; display:flex; justify-content: center; margin-left: 0px; padding-right: 10px; }
   
    .Hardisest_tbody_damgi{transform: scale(1); align-items: center; justify-content: center;   }
    .Hardisest_tbody_add_Icon{transform: scale(1); color: #6e6e6e;}  
    .Hardisest_tbody_download_Icon{  width: 10px;    left:1140px;  position: absolute; margin-top: 36px;}
    .Hardisest_tbody_favorite_Icon{  width: 10px;    left:1220px;  position: absolute; margin-top: 36px;}
    .Hardisest_farEye__Icon{transform: scale(0.69); color: #6e6e6e; margin-top: 5px; position: absolute; margin-left: 2px;}

        .Hardisest_basket_menu {
            position: relative;
            flex-direction: row;
            display: flex;
            justify-content: center;
        }
         .Hardisest_basket_menu li {
            list-style-type: none
        }
    
        .Hardisest_basket_menu_icon :link, 
        .Hardisest_basket_menu_icon :visited { z-index:100; display:block; padding:0 22px; line-height:64px; color:rgb(255, 255, 255);  letter-spacing:-0.5pt;   font-family: Arial, Helvetica, sans-serif;  font-weight:500;   transition: all 0.2s ease;}
        .Hardisest_basket_menu_icon :hover{color:#000; text-decoration:none;  }
    
        .Hardisest_basket_menu div {visibility:hidden; position:absolute; z-index:100; }
        .Hardisest_basket_menu li:hover>div { visibility:visible;    transition: transform 0.6s cubic-bezier(0.83, 0, 0.17, 1);  transform: translate3d(0, 15%, 0);}  
        .Hardisest_basket_menu li li { color:#666; letter-spacing:-0.5pt; padding:3px 15px; text-align:left; font-size:14px; white-space:normal; }
        .Hardisest_basket_menu li li :hover{ color:#ba4148; background:rgb(255, 255, 255); cursor: pointer;}
        .Hardisest_basket_menu li li:first-child {padding-top:20px;}
        .Hardisest_basket_menu li li:last-child  {padding-bottom:20px;}
       
       
        .Hardisest_basket_menu_depth {visibility:hidden; position:absolute; width:150px; z-index:100;}
        .Hardisest_basket_menu_depth ul {margin-top:-40px; box-shadow:0px 0px 10px rgba(102,102,102,0.2); background:rgb(255, 255, 255); box-sizing:border-box; z-index:100 !important; border-radius:5px;}
        .Hardisest_basket_menu_depth li:hover {background:rgb(255, 255, 255);  color:#666; letter-spacing:-0.5pt; text-align:left; font-size:14px; white-space:normal;}
    

    
    
    
}

@media screen and (max-width:1366px) and (min-width:916px) {
  
    .Hardisest_table{
          
        width: 90%;
        /* height: 50px; */
        border-collapse: collapse;
        text-align: center;
        margin-top: 20px;
        margin: auto;
        table-layout: fixed;
        cursor:default;
        outline:none;
        }    
    
        .Hardisest_table_thead{
            width: 90%;
            /* height: 70px; */
            background-color: rgb(255, 255, 255);
            border-bottom: 1px solid #97979754;
            border-top: 1px solid #97979754;
        }

        
   
    /* ///////////////////////////////////T-HEAD///////////////////////////////////////// */

    
    .Hardisest_tableHead_index{    width: 80px;}
    .Hardisest_tableHead_songname{ width: 620px;    padding-left: 0px;text-align: center;}
    .Hardisest_tableHead_genres_edit{   width: 190px; padding-left: 10px; padding-right: 10px;}
    .Hardisest_tableHead_price{   width: 180px; padding-left: 10px; padding-right: 10px;  text-align: center;} 
    .Hardisest_tableHead_view{   width: 120px; padding-left: 0px; text-align: center; font-size: 15px;}

    .Hardisest_tableHead_download{   width: 120px; padding-left: 0px; text-align: center;}
    .Hardisest_tableHead_favorite{width: 80px; padding-left: 0px; padding-right: 0px; text-align: center; }
    .Hardisest_tableHead_damgi{   width: 50px; padding-left: 0px;}
    .Hardisest_tableHead_rating{   width: 180px; padding-left: 0px;}
    
    
    
    /* ///////////////////////////////////T-BODY///////////////////////////////////////// */
    
    .Hardisest_tbody_table{
        border-bottom: 1px solid #97979754;
        text-align: center;
        outline:none;
    }

    .Hardisest_tbody_song_artist{ display:flex; flex-direction: row; cursor: pointer;  position:relative;    text-overflow:ellipsis;   word-break:break-all;  overflow:hidden;  text-align: left;   line-height: 20px; }   

    .Hardisest_tbody_table :hover{background-color:rgb(241, 241, 241); cursor: pointer; }
    .Hardisest_tbody_index{  font-size: 28px; font-family:Arial, Helvetica, sans-serif; font-weight: 600; color:rgb(138, 138, 138); font-style: italic;}
    .Hardisest_tbody_image{
        cursor: pointer;
        min-width: 90px;
        max-width:90px;
        min-height: 90px;
        max-height: 90px;
        object-fit: cover;
        align-items: center;
        transition: 0.3s ease;
        position: relative;
        padding: 10px;
    }
   
    .Hardisest_song_songName {
        flex-direction: column;
        display: flex;
        text-align: left;
        justify-content: center;
        line-height: 21px;
        font-size: 14px;
        color: #666666;
        overflow:hidden;
		white-space : nowrap;
		text-overflow: ellipsis;
    }
    
    .Hardisest_tbody_artistname{  position: relative;  font-size: 10px;}
    .Hardisest_tbody_genres_edit{  opacity: 0.7;   width: 180px;  padding-left: 10px;  padding-right: 10px;  font-size: 15px; text-align: center; } 
    .Hardisest_tbody_price{
        text-align: center;
        color: rgb(138, 138, 138);
        width: 180px;
        padding-left: 10px;
        padding-right: 10px;
        
    }
    .Hardisest_tbody_upgrade{   color: rgb(138, 138, 138); width: 80px;  text-align:center; }
    .Hardisest_tbody_favorite{   color: rgb(138, 138, 138); width: 80px;  text-align: center;}
    .Hardisest_tbody_rating{
        font-size: 9px;
        position: relative;
        margin-top: -10px;
        color: #b3b3b3;
    }
    .Hardisest_tbody_damgi{padding-left: 0px;}
    
    .Hardisest_tbody_rating_star{ padding-left: 0px; padding-right: 0px;  padding-top: 10px;}
    .Hardisest_tbody_rating_star_icon{ padding-top: 0px;  margin-top:10px;display:flex; justify-content: center;}
    .Hardisest_tbody_view{  color: #666666; padding-right: 8px; text-align: center;  font-size: 15px; align-items: center; justify-content: center; }

    
    
    .Hardisest_tbody_add_Icon{transform: scale(1); color: #6e6e6e;}  
    .Hardisest_tbody_download_Icon{  width: 10px;    left:1140px;  position: absolute; margin-top: 36px;}
    .Hardisest_tbody_favorite_Icon{  width: 10px;    left:1220px;  position: absolute; margin-top: 36px;}
    .Hardisest_farEye__Icon{transform: scale(0.69); color: #6e6e6e; margin-top: 5px; position: absolute; margin-left: 2px;}

        .Hardisest_basket_menu {
            position: relative;
            justify-content: center;
            flex-direction: row;
            display: flex;
        }
         .Hardisest_basket_menu li {
            list-style-type: none
        }
    
        .Hardisest_basket_menu_icon :link, 
        .Hardisest_basket_menu_icon :visited { z-index:100; display:block; padding:0 22px; line-height:64px; color:rgb(255, 255, 255);  letter-spacing:-0.5pt;   font-family: Arial, Helvetica, sans-serif;  font-weight:500;   transition: all 0.2s ease;}
        .Hardisest_basket_menu_icon :hover{color:#000; text-decoration:none;  }
    
        .Hardisest_basket_menu div {visibility:hidden; position:absolute; z-index:100; }
        .Hardisest_basket_menu li:hover>div { visibility:visible;    transition: transform 0.6s cubic-bezier(0.83, 0, 0.17, 1);  transform: translate3d(0, 15%, 0);}  
        .Hardisest_basket_menu li li { color:#666; letter-spacing:-0.5pt; padding:3px 15px; text-align:left; font-size:14px; white-space:normal; }
        .Hardisest_basket_menu li li :hover{ color:#ba4148; background:rgb(255, 255, 255); cursor: pointer;}
        .Hardisest_basket_menu li li:first-child {padding-top:20px;}
        .Hardisest_basket_menu li li:last-child  {padding-bottom:20px;}
       
       
        .Hardisest_basket_menu_depth {visibility:hidden; position:absolute; width:150px; z-index:100;}
        .Hardisest_basket_menu_depth ul {margin-top:-20px; box-shadow:0px 0px 10px rgba(102,102,102,0.2); background:rgb(255, 255, 255); box-sizing:border-box; z-index:100 !important; border-radius:5px;}
        .Hardisest_basket_menu_depth li:hover {background:rgb(255, 255, 255);  color:#666; letter-spacing:-0.5pt; text-align:left; font-size:14px; white-space:normal;}
    

    
      
    
}


@media screen and (max-width:915px)  {
  
    .Hardisest_table{
          
        width: 90%;
        /* height: 50px; */
        border-collapse: collapse;
        text-align: center;
        margin-top: 20px;
        margin: auto;
        table-layout: fixed;
        cursor:default;
        outline:none;
        }    
    
        .Hardisest_table_thead{
            width: 90%;
            /* height: 60px; */
            background-color: rgb(255, 255, 255);
            border-bottom: 1px solid #97979754;
            border-top: 1px solid #97979754;
        }

        
    .Hardisest_song_chart{
        background-color: rgb(255, 255, 255);
        display: flex;
        flex-direction: column; 
        position: relative;
        margin-top: 20px;
        margin-bottom: 90px;
    }
    /* ///////////////////////////////////T-HEAD///////////////////////////////////////// */

    
    .Hardisest_tableHead_index{    width: 80px; font-size: 12px;}
    .Hardisest_tableHead_songname{ width: 620px;    padding-left: 0px;text-align: center; font-size: 12px;}
    .Hardisest_tableHead_genres_edit{   width: 190px; padding-left: 10px; padding-right: 10px; font-size: 12px;}
    .Hardisest_tableHead_price{   width: 180px; padding-left: 10px; padding-right: 10px;  text-align: center; font-size: 12px;} 
    .Hardisest_tableHead_download{   width: 120px; padding-left: 0px; text-align: center; font-size: 12px;}
    .Hardisest_tableHead_favorite{width: 80px; padding-left: 0px; padding-right: 0px; text-align: right; font-size: 12px; }
    .Hardisest_tableHead_damgi{   width: 50px; padding-left: 0px; font-size: 12px;}
    .Hardisest_tableHead_rating{   width: 180px; padding-left: 0px;}
    
    
    
    /* ///////////////////////////////////T-BODY///////////////////////////////////////// */
    
    .Hardisest_tbody_table{
        border-bottom: 1px solid #97979754;
        text-align: center;
        outline:none;
    }

    .Hardisest_tbody_song_artist{ display:flex; flex-direction: row; cursor: pointer;  position:relative;    text-overflow:ellipsis;   word-break:break-all;  overflow:hidden;  text-align: left;   line-height: 20px; }   

    .Hardisest_tbody_table :hover{background-color:rgb(241, 241, 241); cursor: pointer; }
    .Hardisest_tbody_index{  font-size: 21px; font-family:Arial, Helvetica, sans-serif; font-weight: 600; color:rgb(138, 138, 138); font-style: italic;}
    .Hardisest_tbody_image{
        cursor: pointer;
        min-width: 75px;
        max-width:75px;
        min-height: 75px;
        max-height: 75px;
        object-fit: cover;
        align-items: center;
        transition: 0.3s ease;
        position: relative;
        padding: 8px;
    }
   
    .Hardisest_song_songName {
        flex-direction: column;
        display: flex;
        text-align: left;
        justify-content: center;
        line-height: 21px;
        font-size: 12px;
        color: #666666;
        overflow:hidden;
		white-space : nowrap;
		text-overflow: ellipsis;
    }
    
    .Hardisest_song_artistname{   color: #666666;   position: relative;  font-size: 10px;}
    .Hardisest_tbody_genres_edit{    color: #666666;  width: 180px;  padding-left: 10px;  padding-right: 10px;  font-size: 12px; text-align: center; } 
    .Hardisest_tbody_price{
        text-align: center;
        color: #666666;
        width: 180px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 12px;
    }
    .Hardisest_tbody_upgrade{  color: #666666; padding-right: 8px; text-align: center;  font-size: 12px; align-items: center; justify-content: center; }
    .Hardisest_tbody_favorite{   color: #666666; width: 80px; padding-right: 30px; text-align: right; font-size: 12px;}
    .Hardisest_tbody_rating{
        font-size: 9px;
        position: relative;
        margin-top: -10px;
        color: #b3b3b3;
    }
    .Hardisest_tbody_damgi{transform: scale(0.8); align-items: center; justify-content: center;  }
    .Hardisest_tbody_rating_star{ padding-left: 0px; padding-right: 0px;  padding-top: 10px;}
    .Hardisest_tbody_rating_star_icon{ padding-top: 0px;  margin-top:10px;}
    .Hardisest_tbody_add_Icon{transform: scale(1); color: #6e6e6e;}  
    .Hardisest_tbody_download_Icon{  width: 10px;    left:1140px;  position: absolute; margin-top: 36px;}
    .Hardisest_tbody_favorite_Icon{  width: 10px;    left:1220px;  position: absolute; margin-top: 36px;}
    
        .Hardisest_basket_menu {
            position: relative;
            flex-direction: row;
            display: flex;
            justify-content: center;
        }
         .Hardisest_basket_menu li {
            list-style-type: none
        }
    
        .Hardisest_basket_menu_icon :link, 
        .Hardisest_basket_menu_icon :visited { align-items: center; justify-content: center; z-index:100; display:flex; padding:0 22px; line-height:64px; color:rgb(255, 255, 255); font-weight:500; letter-spacing:-0.5pt;   font-family: Arial, Helvetica, sans-serif;  font-weight:500;   transition: all 0.2s ease;}
        .Hardisest_basket_menu_icon :hover{color:#000; text-decoration:none;  }
    
        .Hardisest_basket_menu div {visibility:hidden; position:absolute; z-index:100; }
        .Hardisest_basket_menu li:hover>div { visibility:visible;    transition: transform 0.6s cubic-bezier(0.83, 0, 0.17, 1);  transform: translate3d(0, 15%, 0);}  
        .Hardisest_basket_menu li li { color:#666; letter-spacing:-0.5pt; padding:3px 15px; text-align:left; font-size:14px; white-space:normal; }
        .Hardisest_basket_menu li li :hover{ color:#ba4148; background:rgb(255, 255, 255); cursor: pointer;}
        .Hardisest_basket_menu li li:first-child {padding-top:20px;}
        .Hardisest_basket_menu li li:last-child  {padding-bottom:20px;}
       
       
        .Hardisest_basket_menu_depth {visibility:hidden; position:absolute; width:150px; }
        .Hardisest_basket_menu_depth ul {margin-top:-20px; box-shadow:0px 0px 10px rgba(102,102,102,0.2); background:rgb(255, 255, 255); box-sizing:border-box; z-index:100 !important; border-radius:5px;}
        .Hardisest_basket_menu_depth li:hover {background:rgb(255, 255, 255);  color:#666; letter-spacing:-0.5pt; text-align:left; font-size:14px; white-space:normal;}
    


}