@media only screen and (min-width: 1367px) {
  
    
    .Header_Pianissimo_description{
        width: 100%;
        height: 285px;
        /* background:  rgba(223, 223, 223, 0.473) 15%;  */
       /* background: linear-gradient(135deg, #55c5ea 0%, #3fe5b8 100%); */
        margin-top: 0px;
        /* border: #979797 solid 1px ; */
    }
    .Header_Pianissimo_description_img{
        width: 100%;
      
        /* margin-top: 200px; */
        margin-left: 0px;
        margin-top: -4%;
        margin-bottom: -4%;
        margin-left: -2%;
    }
  
    .Header_artist_table{
      
        width: 100%;
        height: 50px;
        border-collapse: collapse;
        text-align: center;
        /* margin: auto; */
 
        margin-top: 20px;
        table-layout: fixed;
        cursor:default;
        outline:none;
        }    

    .Header_artist_SUBtable{ 
        border-top: 1px solid #97979754; 
        border-bottom: 1px solid #97979754; 
        border-collapse: collapse;
        text-align: center;
        height: 56px;
        outline:none;
        }    
       
        .Header_artist_index{ width: 80px; font-size: 20px; font-family:Arial, Helvetica, sans-serif; font-weight: 600;     color: rgb(145, 145, 145); font-style: italic;}
        .Header_artist_photoURL{transform: scale(0.7); padding-left: 10px; }
        .Header_artist_displayName{ padding-left: 6px; font-size: 15px; font-family:Arial, Helvetica, sans-serif; font-weight: 300;     color: #666666; font-style: normal; text-align: left;}

  
    .Header_song_thead_tr{
    
        width: 95%;
        height: 50px;
        background-color: rgb(255, 255, 255);
        border-bottom: 1px solid #97979754;
        border-top: 1px solid #97979754;
      
    
    }
    
    .mainpage_top_song10{
        width: 95%;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }
    .Header_song_table{
      
    width: 95%;
    height: 50px;
    border-collapse: collapse;
    text-align: center;
    /* margin: auto; */
    margin-left: 50px;
    margin-top: 20px;
    table-layout: fixed;
    cursor:default;
    outline:none;
    }    
    
    
    .Header_songhead_thead2{   text-align: center; width: 80px;  color: #464646; font-size: 16px;}
    .Header_songhead_songname{ width: 485px; text-align: center; color: #464646; font-size: 16px;}
    /* .Header_songhead_songname{ width: 485px;    padding-left: 85px;text-align: left; color: #464646; font-size: 16px;} */
    
    .Header_songhead_country{   width: 150px; color: #464646; font-size: 16px;}
    .Header_songhead_price{ text-align: center;    color: #464646;}
    .Header_songhead_view{ text-align: center;   width: 80px; padding-left: 0px; color: #464646}

    .Header_songhead_download{ text-align: center;   width: 80px; padding-left: 0px; color: #464646}
    .Header_songhead_like{ text-align: center;   width: 80px; padding-left: 0px; color: #464646}
    
    .Header_songhead_damgi{  text-align: center; width: 40px; padding-left: 0px; color: #464646}
    
    /* .Header_song_subtable{ border-bottom: 1px solid #97979754; text-align: center; width: 970px;} */
    .Header_song_subtable :hover{background-color:rgb(241, 241, 241);   cursor: pointer;}
    .Header_song_tr{width: 970px; }
    .Header_artist_SUBtable :hover{background-color:rgb(241, 241, 241);  cursor: pointer; }
    .Header_song_index{ width: 80px; font-size: 32px; font-family:Arial, Helvetica, sans-serif; font-weight: 600;     color: rgb(145, 145, 145); font-style: italic;}
    .Header_song__image{
        cursor: pointer;
        min-width: 85px;
        max-width:85px;
        min-height: 85px;
        max-height: 85px;
        object-fit: cover;
        transition: 0.3s ease;
        align-items: center;
        position: relative;
        padding: 10px;
        display: flex;

        /* margin-top: -20px; */
    }
    .Header_song_subtable{
        border-bottom: 1px solid #97979754; 
        text-align: center;
         width: 970px;
        height: 90px;
        outline:none;
    }
    .Header_song_songName {
        flex-direction: column;
        display: flex;
        text-align: left;
        justify-content: center;
        line-height: 21px;
        /* margin-left: 90px; */
        font-size: 14px;
        color: #666666;
    }
    
    .Header_song_td{ display:flex; flex-direction: row; cursor: pointer;  position:relative;    text-overflow:ellipsis;   word-break:break-all;  overflow:hidden;  text-align: left;   line-height: 20px; }   
    .Header_song_artistname{  position: relative;  font-size: 10px;}
    .Header_song_country{ color: #666666;  width: 150px;  padding-left: 10px;  padding-right: 10px;  font-size: 15px; text-align: center;} 
    .Header_song_view{   color: rgb(138, 138, 138); width: 60px; text-align: center;}

    .Header_song_upgrade{   color: rgb(138, 138, 138); width: 60px; text-align: center;}
    .Header_song_like{   color: rgb(138, 138, 138); width: 60px;  text-align: center;}
    
    .Header_song_Icon{  width: 10px;    left:885px;  position: absolute; margin-top: 34px;}
    .farEye__Icon{transform: scale(0.69); color: #6e6e6e; margin-top: 5px; position: absolute; margin-left: 2px;}
    .ArrowDownward__Icon{transform: scale(0.69); color: #6e6e6e; margin-top: 0px; position: absolute;}
    .PlayArrowOutlinedIcon__Icon{transform: scale(1); color: #6e6e6e;}  
    
    
    .Header_song_price{width: 120px;  color: #666666; padding-left: 10px;  padding-right: 10px;  }
    .Header_song_AddIcon{padding-left: 0px;}
    
    
    .header_main_banner_hot100 {
        font-family: "Poppins",sans-serif;
         height: 250px;
         width: 250px;
         background: linear-gradient(135deg, #ffb2d6 0%, #b4095f 100%);   
     }
     .header_main_banner_NEW100 {
         font-family: "Poppins",sans-serif;
          height: 250px;
          width: 250px;
          background: linear-gradient(to right, #ae3eb7 0%, #5f286a 100%); 
      }
      .header_main_banner_ARTIST100 {
         font-family: "Poppins",sans-serif;
          height: 250px;
          width: 250px;
          background: linear-gradient(135deg, #648caf 0%, #225269 80%, #225269 100%);
      }
      .header_main_banner_song__image{
        max-width:80px;
        max-height: 80px;
        object-fit: cover;
        align-items: center;
        transition: 0.3s ease;
        padding: 10px;
    }
    
    .header_main_hardiest_hardiest100 {
        font-family: "Poppins",sans-serif;
         height: 140px;
         width: 140px;
         background: linear-gradient(135deg, #d3d3d3 0%, #808080 50%, #444444 100%);
         margin-left: 30px;
         margin-top: 20px;
            }
    
    .header_main_artist_signup{
        margin-left: 150px;
        width:420px;
        display: flex;
        flex-direction: column; 
        margin-top: 30px;
        background: linear-gradient(
                    135deg
                    , #ff9e3f 0%, #b260b3 100%);
                    
       height: 300px;
              
            }
    
    
    
    
    /* ////////////////더보기///////// */
    #main_container a.more {position:absolute; 
        right:0; top:0; 
        /* background:url('../images/icon_more.png') left center no-repeat;  */
        cursor: pointer;
        padding: 0;
        /* transition: color .123s, opacity .123s; */
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.0rem;
        letter-spacing: .03em;
        line-height: 1.46em;
        color: #76badf;
        padding-left: 10px;
    }
    
    #main_container .list_area {overflow:hidden; margin-bottom:40px;}
     
    
    
    /* //////////아티스트 순위 index////// */
    .num {display:inline-block;  text-align:left; font-size:21px; 
        font-weight: 600;
        line-height: 1.46em;
        width: 30px;
    }
    .num.round {color:rgb(51, 51, 51);}
    
    .header_main_banner_artist__image{
        width: 80px;
        height: 80px;
        max-width:100px;
        max-height: 100px;
        object-fit: cover;
        align-items: center;
        transition: 0.3s ease;
        /* position: absolute; */
        padding: 10px;
       
    }
    
    
    .Header_whatis_Pianissimo_title{
        margin-top: 26px;
    }
    
    
    .header_main_banner_artistjoin_text {
    
        font-family: "Poppins",sans-serif;
        /* list-style: none; */
        text-transform: uppercase;
        color: #fff;
        font-size: 1.3em;
        line-height: .833em;
        /* box-sizing: inherit; */
        word-break: break-all;
        font-weight: 600;
        text-align: left;
        width: 400px;
        display: block;
        padding-top: 20px;
        margin-left: 12px;
        /* margin-top: 30px; */
        }
    
    
    
    .Header_banner_chart_title_more{
    /* background:url('../images/icon_more.png') left center no-repeat;  */
    border: 0;
    cursor: pointer;
    padding: 0;
    background: transparent;
    display: inline-block;
    text-decoration: none;
    transition: color .123s, opacity .123s;
    flex-shrink: 0;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 3rem;
    font-size: 1.0rem;
    letter-spacing: .03em;
    line-height: 1.46em;
    color: #76badf;
    margin-top: 17px;
    }
    
    
    .MainPage_basket_menu {
        position: relative;
        flex-direction: row;
        display: flex;
        justify-content: center;
    }
     .MainPage_basket_menu li {
        list-style-type: none
    }

    .MainPage_basket_menu_icon :link, 
    .MainPage_basket_menu_icon :visited { align-items: center; justify-content: center; z-index:100; display:flex; padding:0 22px; line-height:64px; color:rgb(255, 255, 255); font-weight:500; letter-spacing:-0.5pt;   font-family: Arial, Helvetica, sans-serif; transition: all 0.2s ease;}
    .MainPage_basket_menu_icon :hover{color:#000; text-decoration:none;  }

    .MainPage_basket_menu div {visibility:hidden; position:absolute; z-index:100; }
    .MainPage_basket_menu li:hover>div { visibility:visible;    transition: transform 0.6s cubic-bezier(0.83, 0, 0.17, 1);  transform: translate3d(0, 15%, 0);}  
    .MainPage_basket_menu li li { color:#666; letter-spacing:-0.5pt; padding:3px 15px; text-align:left; font-size:15px; white-space:normal; }
    .MainPage_basket_menu li li :hover{ color:#ba4148; background:rgb(255, 255, 255); cursor: pointer;}
    .MainPage_basket_menu li li:first-child {padding-top:20px;}
    .MainPage_basket_menu li li:last-child  {padding-bottom:20px;}
   
    .MainPage_basket_menu_depth {visibility:hidden; position:absolute; width:120%; left:-40% }
    .MainPage_basket_menu_depth ul {margin-top:-20px; box-shadow:0px 0px 10px rgba(102,102,102,0.2); background:rgb(255, 255, 255); box-sizing:border-box; z-index:100 !important; border-radius:5px;}
    .MainPage_basket_menu_depth li:hover {background:rgb(255, 255, 255);  color:#666; letter-spacing:-0.5pt; text-align:left; font-size:15px; white-space:normal;}





}






  @media screen and (max-width:1366px) and (min-width:916px) {

    .Header_Pianissimo_description{
        width: 285px;
        height: 285px;
        /* background:  rgba(223, 223, 223, 0.473) 15%;  */
       /* background: linear-gradient(135deg, #55c5ea 0%, #3fe5b8 100%); */
        margin-top: 0px;
        /* border: #979797 solid 1px ; */
    }
    .Header_Pianissimo_description_img{
        width:285px;
        height: 230px;
        /* margin-top: 200px; */
        margin-left: 0px;
    }
    
    
    
    .Header_song_thead_thead_tr{
        /* border-bottom:  1px solid #8b8b8b98;
        border-top: 1px solid #8b8b8b98;
        width: 970px;
        height: 50px;
        background-color: rgb(255, 255, 255); */
    
    
    }
    .Header_song_thead_tr{
    
        width: 100%;
        height: 50px;
        background-color: rgb(255, 255, 255);
        border-bottom: 1px solid #97979754;
        border-top: 1px solid #97979754;
     
    
    }
    
    .mainpage_top_song10{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 30px;
    }
    .Header_song_table{
      
    width: 100%;
    height: 50px;
    border-collapse: collapse;
    text-align: center;
    margin: auto;
    /* margin-left: 30px; */
    table-layout: fixed;
    cursor:default;
    outline:none;
    margin-top: 20px;
    }    
    
    
    .Header_songhead_thead2{   text-align: center; width: 80px;  color: #464646; font-size: 16px;}
    .Header_songhead_songname{ width: 485px; text-align: center; color: #464646; font-size: 16px;}
    /* .Header_songhead_songname{ width: 485px;    padding-left: 85px;text-align: left; color: #464646; font-size: 16px;} */
    
    .Header_songhead_country{   width: 150px; color: #464646; font-size: 16px;}
    .Header_songhead_price{ text-align: center;  width: 120px;  color: #464646;}
    .Header_songhead_download{ text-align: center;   width: 80px; padding-left: 0px; color: #464646}
    .Header_songhead_like{ text-align: center;   width: 80px; padding-left: 0px; color: #464646}
    
    .Header_songhead_damgi{  text-align: center; width: 40px; padding-left: 0px; color: #464646}
    
    /* .Header_song_subtable{ border-bottom: 1px solid #97979754; text-align: center; width: 970px;} */
    .Header_song_subtable :hover{background-color:rgb(241, 241, 241);     cursor: pointer;}
    .Header_song_tr{width: 970px; }
    .Header_song_index{ width: 80px; font-size: 32px; font-family:Arial, Helvetica, sans-serif; font-weight: 600;     color: rgb(145, 145, 145); font-style: italic;}
    .Header_song__image{
        cursor: pointer;
        min-width: 85px;
        max-width:85px;
        min-height: 85px;
        max-height: 85px;
        object-fit: cover;
        transition: 0.3s ease;
        align-items: center;
        position: relative;
        padding: 10px;
        display: flex;
    }
    .Header_song_subtable{
        border-bottom: 1px solid #97979754; 
        text-align: center;
         width: 970px;
        height: 90px;
        outline:none;
    }
    .Header_song_songName {
        flex-direction: column;
        display: flex;
        text-align: left;
        justify-content: center;
        line-height: 21px;
        /* margin-left: 90px; */
        font-size: 14px;
        color: #666666;
    }
    
    .Header_song_td{ display:flex; flex-direction: row; cursor: pointer;  position:relative;    text-overflow:ellipsis;   word-break:break-all;  overflow:hidden;  text-align: left;   line-height: 20px; }   
    .Header_song_artistname{  position: relative;  font-size: 10px;}
    .Header_song_country{ color: #666666;  width: 150px;  padding-left: 10px;  padding-right: 10px;  font-size: 15px; text-align: center;} 
    .Header_song_upgrade{   color: rgb(138, 138, 138); width: 60px; padding-right: 0px; text-align: center;}
    .Header_song_like{   color: rgb(138, 138, 138); width: 60px; padding-right: 0px; text-align: center;}
    .Header_song_view{   color: rgb(138, 138, 138); width: 60px; text-align: center;}

    .Header_song_Icon{  width: 10px;    left:885px;  position: absolute; margin-top: 34px;}
    .farEye__Icon{transform: scale(0.69); color: #6e6e6e; margin-top: 5px; position: absolute; margin-left: 2px;}

    .ArrowDownward__Icon{transform: scale(0.69); color: #6e6e6e; margin-top: 0px; position: absolute;}
    .PlayArrowOutlinedIcon__Icon{transform: scale(1); color: #6e6e6e;}  
    
    
    .Header_song_price{width: 120px;  color: #666666; padding-left: 10px;  padding-right: 10px;  }
    .Header_song_AddIcon{padding-left: 0px;}
    
    
    .header_main_banner_hot100 {
        font-family: "Poppins",sans-serif;
         height: 210px;
         width: 210px;
         background: linear-gradient(135deg, #ffb2d6 0%, #b4095f 100%);   
     }
     .header_main_banner_NEW100 {
         font-family: "Poppins",sans-serif;
         height: 210px;
         width: 210px;
          background: linear-gradient(to right, #ae3eb7 0%, #5f286a 100%); 
      }
      .header_main_banner_ARTIST100 {
         font-family: "Poppins",sans-serif;
         height: 210px;
         width: 210px;
          background: linear-gradient(135deg, #648caf 0%, #225269 80%, #225269 100%);
      }
      .header_main_banner_song__image{
        max-width:70px;
        max-height: 70px;
        object-fit: cover;
        align-items: center;
        transition: 0.3s ease;
        padding: 8px;
    }
    
    .header_main_hardiest_hardiest100 {
        font-family: "Poppins",sans-serif;
         height: 140px;
         width: 140px;
         background: linear-gradient(135deg, #d3d3d3 0%, #808080 50%, #444444 100%);
         margin-left: 30px;
         margin-top: 20px;
            }
    
    .header_main_artist_signup{
        margin-left: 150px;
        width:420px;
        display: flex;
        flex-direction: column; 
        margin-top: 30px;
        background: linear-gradient(
                    135deg
                    , #ff9e3f 0%, #b260b3 100%);
                    
       height: 300px;
              
            }
    
    
    
    
    /* ////////////////더보기///////// */
    #main_container a.more {position:absolute; 
        right:0; top:0; 
        /* background:url('../images/icon_more.png') left center no-repeat;  */
        cursor: pointer;
        padding: 0;
        /* transition: color .123s, opacity .123s; */
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.0rem;
        letter-spacing: .03em;
        line-height: 1.46em;
        color: #76badf;
        padding-left: 10px;
    }
    
    #main_container .list_area {overflow:hidden; margin-bottom:40px;}
     
    
    
    /* //////////아티스트 순위 index////// */
    .num {display:inline-block;  text-align:left; font-size:21px; 
        font-weight: 600;
        line-height: 1.46em;
        width: 30px;
    }
    .num.round {color:rgb(51, 51, 51);}
    
    .header_main_banner_artist__image{
        width: 80px;
        height: 80px;
        max-width:100px;
        max-height: 100px;
        object-fit: cover;
        align-items: center;
        transition: 0.3s ease;
        /* position: absolute; */
        padding: 10px;
       
    }
    
    
    .Header_whatis_Pianissimo_title{
        margin-top: 26px;
    }
    
    
    .header_main_banner_artistjoin_text {
    
        font-family: "Poppins",sans-serif;
        /* list-style: none; */
        text-transform: uppercase;
        color: #fff;
        font-size: 1.3em;
        line-height: .833em;
        /* box-sizing: inherit; */
        word-break: break-all;
        font-weight: 600;
        text-align: left;
        width: 400px;
        display: block;
        padding-top: 20px;
        margin-left: 12px;
        /* margin-top: 30px; */
        }
    
    
    
    .Header_banner_chart_title_more{
    /* background:url('../images/icon_more.png') left center no-repeat;  */
    border: 0;
    cursor: pointer;
    padding: 0;
    background: transparent;
    display: inline-block;
    text-decoration: none;
    transition: color .123s, opacity .123s;
    flex-shrink: 0;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 3rem;
    font-size: 1.0rem;
    letter-spacing: .03em;
    line-height: 1.46em;
    color: #76badf;
    margin-top: 17px;
    }
    
    
    
    
    


  }











  
  @media screen and (max-width:915px) {

    .Header_Pianissimo_description{
        width: 285px;
        height: 285px;
        /* background:  rgba(223, 223, 223, 0.473) 15%;  */
       /* background: linear-gradient(135deg, #55c5ea 0%, #3fe5b8 100%); */
        margin-top: 0px;
        /* border: #979797 solid 1px ; */
    }
    .Header_Pianissimo_description_img{
        width:285px;
        height: 230px;
        /* margin-top: 200px; */
        margin-left: 0px;
    }
    
    
  
    .Header_song_thead_tr{
    
        width: 100%;
        height: 50px;
        background-color: rgb(255, 255, 255);
        border-bottom: 1px solid #97979754;
        border-top: 1px solid #97979754;
      
    
    }
    
    .mainpage_top_song10{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 30px;
    }
    .Header_song_table{
      
    width: 100%;
    height: 50px;
    border-collapse: collapse;
    text-align: center;
    margin: auto;
    /* margin-left: 30px; */
    table-layout: fixed;
    cursor:default;
    outline:none;
    margin-top: 20px;
    
    }    
    
    
    .Header_songhead_thead2{   text-align: center; color: #464646; font-size: 12px;}
    .Header_songhead_songname{ width: 485px; text-align: center; color: #464646; font-size: 12px;}
    /* .Header_songhead_songname{ width: 485px;    padding-left: 85px;text-align: left; color: #464646; font-size: 16px;} */
    
    .Header_songhead_country{   width: 150px; color: #464646; font-size: 12px;}
    .Header_songhead_price{ text-align: center;  width: 120px;  color: #464646; font-size: 12px;}
    .Header_songhead_download{ text-align: center;   width: 80px; padding-left: 0px; color: #464646; font-size: 12px;}
    .Header_songhead_like{ text-align: center;   width: 80px; padding-left: 0px; color: #464646}
    
    .Header_songhead_damgi{  text-align: center; width: 40px; padding-left: 0px; color: #464646; font-size: 12px;}
    
    /* .Header_song_subtable{ border-bottom: 1px solid #97979754; text-align: center; width: 970px;} */
    .Header_song_subtable :hover{background-color:rgb(241, 241, 241);    cursor: pointer; }
    .Header_song_tr{width: 970px; }
    .Header_song_index{ width: 80px; font-size: 22px; font-family:Arial, Helvetica, sans-serif; font-weight: 600;     color: rgb(145, 145, 145); font-style: italic;}
    .Header_song__image{
        cursor: pointer;
        min-width: 65px;
        max-width:65px;
        min-height: 65px;
        max-height: 65px;
        object-fit: cover;
        transition: 0.3s ease;
        align-items: center;
        position: relative;
        padding: 10px;
        display: flex;
    }
    .Header_song_subtable{
        border-bottom: 1px solid #97979754; 
        text-align: center;
         width: 970px;
        /* height: 90px; */
        outline:none;
    }
    .Header_song_songName {
        flex-direction: column;
        display: flex;
        text-align: left;
        justify-content: center;
        line-height: 21px;
        font-size: 12px;
        color: #666666;
        overflow:hidden;
		white-space : nowrap;
		text-overflow: ellipsis;



    }
    
    .Header_song_td{ display:flex; flex-direction: row; cursor: pointer;  position:relative;    text-overflow:ellipsis;   word-break:break-all;  overflow:hidden;  text-align: left;   line-height: 20px; }   
    .Header_song_artistname{  position: relative;  font-size: 10px;}
    .Header_song_country{ color: #666666;  width: 150px;  padding-left: 10px;  padding-right: 10px;  font-size: 12px; text-align: center;} 
    .Header_song_upgrade{   color: rgb(138, 138, 138); width: 60px; padding-right: 0px; text-align: center; font-size: 12px;}
    .Header_song_like{   color: rgb(138, 138, 138); width: 60px; padding-right: 25px; text-align: right;}
    .Header_song_Icon{  width: 10px;    left:885px;  position: absolute; margin-top: 34px;}
    .Header_song_view{   color: rgb(138, 138, 138); width: 60px; text-align: center;}

    .ArrowDownward__Icon{transform: scale(0.55); color: #6e6e6e; margin-top: 0px; position: absolute; margin-top: -2px;}
    .PlayArrowOutlinedIcon__Icon{transform: scale(0.8); color: #6e6e6e;}  
    
    .farEye__Icon{transform: scale(0.55); color: #6e6e6e; margin-top: 5px; position: absolute; margin-left: 2px;}

    .Header_song_price{width: 120px;  color: #666666; padding-left: 10px;  padding-right: 10px;  font-size: 12px;}
    .Header_song_AddIcon{padding-left: 0px;}
    
    
    .header_main_banner_hot100 {
        font-family: "Poppins",sans-serif;
        height: 100%;
         width: 80%;
         background: linear-gradient(135deg, #ffb2d6 0%, #b4095f 100%);   
        padding-bottom: 10px;
        }
     .header_main_banner_NEW100 {
         font-family: "Poppins",sans-serif;
         height: 100%;
         width: 80%;
          background: linear-gradient(to right, #ae3eb7 0%, #5f286a 100%); 
          padding-bottom: 10px;

        }
      .header_main_banner_ARTIST100 {
         font-family: "Poppins",sans-serif;
         height: 100%;
         width: 80%;
          background: linear-gradient(135deg, #648caf 0%, #225269 80%, #225269 100%);
          padding-bottom: 10px;

        }
      .header_main_banner_song__image{
        /* max-width:40px;
        max-height: 40px; */
        max-width:  100%;
        max-height: 100%;
        object-fit: cover;
        align-items: center;
        transition: 0.3s ease;
       
    }
    
    .header_main_hardiest_hardiest100 {
        font-family: "Poppins",sans-serif;
        height: 100%;
        width: 80%;
         background: linear-gradient(135deg, #d3d3d3 0%, #808080 50%, #444444 100%);
         /* margin-left: 10px; */
         margin-top: 20px;
            }
    
    .header_main_artist_signup{
        margin-left: 150px;
        width:420px;
        display: flex;
        flex-direction: column; 
        margin-top: 30px;
        background: linear-gradient(
                    135deg
                    , #ff9e3f 0%, #b260b3 100%);
                    
       height: 300px;
              
            }
    
    
    
    
    /* ////////////////더보기///////// */
    #main_container a.more {position:absolute; 
        right:0; top:0; 
        /* background:url('../images/icon_more.png') left center no-repeat;  */
        cursor: pointer;
        padding: 0;
        /* transition: color .123s, opacity .123s; */
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.0rem;
        letter-spacing: .03em;
        line-height: 1.46em;
        color: #76badf;
        padding-left: 10px;
    }
    
    #main_container .list_area {overflow:hidden; margin-bottom:40px;}
     
    
    
    /* //////////아티스트 순위 index////// */
    .num {display:inline-block;  text-align:left; font-size:21px; 
        font-weight: 600;
        line-height: 1.46em;
        width: 30px;
    }
    .num.round {color:rgb(51, 51, 51);}
    
    .header_main_banner_artist__image{
        width: 80px;
        height: 80px;
        max-width:100px;
        max-height: 100px;
        object-fit: cover;
        align-items: center;
        transition: 0.3s ease;
        /* position: absolute; */
        padding: 10px;
       
    }
    
    
    .Header_whatis_Pianissimo_title{
        margin-top: 26px;
    }
    
    
    .header_main_banner_artistjoin_text {
    
        font-family: "Poppins",sans-serif;
        /* list-style: none; */
        text-transform: uppercase;
        color: #fff;
        font-size: 1.3em;
        line-height: .833em;
        /* box-sizing: inherit; */
        word-break: break-all;
        font-weight: 600;
        text-align: left;
        width: 400px;
        display: block;
        padding-top: 20px;
        margin-left: 12px;
        /* margin-top: 30px; */
        }
    
    
    
    .Header_banner_chart_title_more{
    /* background:url('../images/icon_more.png') left center no-repeat;  */
    border: 0;
    cursor: pointer;
    padding: 0;
    background: transparent;
    display: inline-block;
    text-decoration: none;
    transition: color .123s, opacity .123s;
    flex-shrink: 0;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 3rem;
    font-size: 1.0rem;
    letter-spacing: .03em;
    line-height: 1.46em;
    color: #76badf;
    margin-top: 17px;
    }
    
  

  }
