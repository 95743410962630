


@media only screen and (min-width: 1024px){

    .notice_search{
        width: 1200px;
        height: auto;
        border-collapse: collapse;
        text-align: left;
        margin: 10px auto;
        table-layout: fixed;
        cursor:default;
        outline:none;
    }   

.notice_search_content{
    /* height: 200px; */
    padding-top: 30px;
    padding-bottom: 30px;
    /* justify-content: center; */
    flex-direction: column;
    /* margin: auto; */
    /* display: flex; */
    display:block;
    align-items: left;
    text-align: left;
    justify-content: left;
    /* justify-content: flex-start; */
}
   
}


@media screen and (max-width:850px) {
   
    /* .navbar__center{margin-top: 130px; position: absolute;} */
}

