
@media only screen and (min-width: 1024px){

  .ArtistJoin_piano_img{
      width: 250px;
    position: relative;
    /* left: 1150px; */
    }
    .PIANISSIMO_text{
      /* background-image: url(".."); */
      color:#ba4148;
    } 
    #Artist_Join_background{
      color:#ffffff86;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    #Artist_Join_background::after {
      width: 100%;
      height: 100%;
      content: "";
      background-image: url("../../images/wall.png");
      top: 0;
      left: 0;
      position: absolute;
      z-index: -1;
      opacity: 0.1;
    }
}


@media screen and (max-width:850px) {
   
    /* .navbar__center{margin-top: 130px; position: absolute;} */
}