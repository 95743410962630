
@media only screen and (min-width: 1367px) {
.ArtistMain_Slider {
	width: 100%;
	height: 250px;
}


.ArtistMain_Slider_text {
	height: 60px;
	width: 54%;
	text-align: right;
	left: 43.5%;
	top: 53%;
	z-index: 300;
	position: absolute;
	font-size: 38px;
	line-height: 32px;
	padding-top: 10px;
	overflow: hidden;
	word-break: break-all;
	text-overflow: ellipsis;
	white-space: nowrap;
	animation: titleAnimation 6s ease-in-out infinite;
}

.ArtistMain_Slider_text2 {
	width: 54%;
	height: 45px;
	text-align: right;
	left: 43.5%;
	top: 73%;
	padding-top: 10px;
	/* margin-left: 1200px; */
	z-index: 300;
	position: absolute;
	font-size: 24px;
	line-height: 21px;
	overflow: hidden;
	word-break: break-all;
	text-overflow: ellipsis;
	white-space: nowrap;
	animation: titleAnimation 6s ease-in-out infinite;

}

@keyframes titleAnimation {
    0% {
      transform: translateY(50px);
      opacity: 0;
      -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
      clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
  }
  20% {
      transform: translateY(0);
      opacity: 1;
      -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
      clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
  }
  80% {
       transform: translateY(0);
       opacity: 1;
      -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
      clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
  }
  100% {
       transform: translateY(-50px);
       opacity: 0;
      -webkit-clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
      clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
    
  }
}





.ArtistMain_Slider__image24 {
	object-fit: cover;
	opacity: 0.9 !important;
	width: 100%;
	height: 250px;
}



  

.ArtistMain_Slider__image25 {
	width: 250px;
	height: 250px;
	object-fit: cover;
	z-index: 1 !important;
	position: relative;
	margin-left: 30px;	
}
.ArtistMain_Slider-image_opacity1 {
	position: absolute;
}

.slick-slide.slick-center img {
	opacity: 1 !important;
	z-index: 1 !important;
}
.slick-slide {
	position: relative;
	z-index: 2;
}
}

@media screen and (max-width:1366px) and (min-width:916px) {

	.ArtistMain_Slider {
		width: 100%;
		height: 200px;
	}
	
	
	.ArtistMain_Slider_text {
		height: 60px;
		width: 50%;
		text-align: right;
		left: 45%;
		top: 50%;
		z-index: 300;
		position: absolute;
		font-size: 32px;
		line-height: 32px;
		padding-top: 10px;
		overflow: hidden;
		word-break: break-all;
		text-overflow: ellipsis;
		white-space: nowrap;
		animation: titleAnimation 6s ease-in-out infinite;
	}
	
	.ArtistMain_Slider_text2 {
		width: 50%;
		height: 45px;
		text-align: right;
		left: 45%;
		top: 71.5%;
		padding-top: 10px;
		/* margin-left: 1200px; */
		z-index: 300;
		position: absolute;
		font-size: 21px;
		line-height: 21px;
		overflow: hidden;
		word-break: break-all;
		text-overflow: ellipsis;
		white-space: nowrap;
		animation: titleAnimation 6s ease-in-out infinite;
	
	}
	
	@keyframes titleAnimation {
		0% {
		  transform: translateY(50px);
		  opacity: 0;
		  -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
		  clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
	  }
	  20% {
		  transform: translateY(0);
		  opacity: 1;
		  -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
		  clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
	  }
	  80% {
		   transform: translateY(0);
		   opacity: 1;
		  -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
		  clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
	  }
	  100% {
		   transform: translateY(-50px);
		   opacity: 0;
		  -webkit-clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
		  clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
		
	  }
	}
	
	
	.ArtistMain_Slider__image24 {
		object-fit: cover;
		opacity: 0.9 !important;
		width: 100%;
		height: 200px;
	}
	
	.ArtistMain_Slider__image25 {
		width: 200px;
		height: 200px;
		object-fit: cover;
		z-index: 1 !important;
		position: relative;
		margin-left: 30px;	
	}
	.ArtistMain_Slider-image_opacity1 {
		position: absolute;
	}
	
	.slick-slide.slick-center img {
		opacity: 1 !important;
		z-index: 1 !important;
	}
	.slick-slide {
		position: relative;
		z-index: 2;
	}


}

@media screen and (max-width:915px) {

	.ArtistMain_Slider {
		width: 100%;
		height: 160px;
	}
	
	
	.ArtistMain_Slider_text {
		height: 60px;
		width: 40%;
		text-align: right;
		left: 54%;
		top: 47%;
		z-index: 300;
		position: absolute;
		font-size: 28px;
		line-height: 32px;
		padding-top: 10px;
		overflow: hidden;
		word-break: break-all;
		text-overflow: ellipsis;
		white-space: nowrap;
		animation: titleAnimation 6s ease-in-out infinite;
	}
	
	.ArtistMain_Slider_text2 {
		width: 40%;
		height: 45px;
		text-align: right;
		left: 54%;
		top: 68%;
		padding-top: 10px;
		/* margin-left: 1200px; */
		z-index: 300;
		position: absolute;
		font-size: 18px;
		line-height: 21px;
		overflow: hidden;
		word-break: break-all;
		text-overflow: ellipsis;
		white-space: nowrap;
		animation: titleAnimation 6s ease-in-out infinite;
	
	}
	
	@keyframes titleAnimation {
		0% {
		  transform: translateY(50px);
		  opacity: 0;
		  -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
		  clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
	  }
	  20% {
		  transform: translateY(0);
		  opacity: 1;
		  -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
		  clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
	  }
	  80% {
		   transform: translateY(0);
		   opacity: 1;
		  -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
		  clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
	  }
	  100% {
		   transform: translateY(-50px);
		   opacity: 0;
		  -webkit-clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
		  clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
		
	  }
	}
	
	
	.ArtistMain_Slider__image24 {
		object-fit: cover;
		opacity: 0.9 !important;
		width: 100%;
		height: 160px;
	}
	
	.ArtistMain_Slider__image25 {
		width: 160px;
		height: 160px;
		object-fit: cover;
		z-index: 1 !important;
		position: relative;
	}
	.ArtistMain_Slider-image_opacity1 {
		position: absolute;
	}
	
	.slick-slide.slick-center img {
		opacity: 1 !important;
		z-index: 1 !important;
	}
	.slick-slide {
		position: relative;
		z-index: 2;
	}

}