

/* .contain2 {background-color: #FF5C5C; height: 0px; width: 1450px; margin-top: 64px; } */
/* .contain3 {position: relative;     flex-direction: row;  display: flex; }  

.contain4 { 
        position: relative;    height: auto;  margin-top: 64px;
         flex-direction: row;  display: flex;   
       
             } */
 /* .Artist_Product_midiPiano {

    background-color: rgb(155, 155, 155);
    width: 1450px;
    height: 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width:1200px;
} */

/* .Artist_Product_sheet_PDF {
    background-color: rgb(255, 255, 255);
    position: absolute;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    overflow-y: hidden;
} */



.youtube_main {

    background-color: rgb(255, 255, 255);
    /* width: 1450px; */
    height: 730px;
    position: absolute;
    display: flex;
    flex-direction: column;
    /* min-width:1200px; */
    overflow-x: auto;
    overflow-y: hidden;
}


/* 
.Artist_Product_Right_menu {
    background-color: rgb(87, 87, 87);
    width: 440px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
} */


/* .Artist_Product_Right_menu_song_name{
 text-align: center;
 font-size: 36px;
 color: rgb(255, 255, 255);
 padding-top: 10px;
 word-break:break-all;
 width: 350px;
 height: 64px;
 margin: 20px auto;
 text-overflow:ellipsis; 
 display: block;
 overflow: hidden;
white-space: nowrap;
font-weight: 600;
} */
.product_song {

    flex-direction: column;
    width: 350px;
    height: 350px;
    align-items: center;
    display: flex;
    margin: 20px auto;
    border-radius: 300px;
    align-items: center;
    background-color: rgb(58, 58, 58);

   
}
/* 
.product_song__image{


    min-width: 350px;
    max-width:350px;
    min-height: 350px;
    max-height: 350px;
    object-fit: cover;
    border-radius: 350px;
    align-items: center;
   
} */


/* .Artist_Product_SubBox_Info{
    background-color: rgba(218, 218, 218, 0.171);
    border-radius: 150px;
    width: 190px;
    height: 30px;
    margin-top: 10px;
} */



/* .Artist_Product_SubBox_Info_artist{
    margin: auto;
    font-weight: 400;
    text-align: center;
    overflow: hidden;
    word-break:break-all;
    text-overflow:ellipsis; 
   white-space: nowrap;
   color: #fff;
   width: 150px;
   display: block;
padding-top: 3px;

} */
.p_con_down_icon{
    color: #fff;
    position: relative;
    width: 30px;
    transform: scale(0.7);
    /* left: 344px;
    top: 577px; */
    
}


/* .Artist_Product_Bottom_menu{
    background-color: rgb(255, 255, 255);
    position: absolute;
    display: flex;
    flex-direction: column;
    border-top: 1px dashed #7979795e;
} */

/* .Artist_Product_Bottom_menu_mainbox{
    position: relative;
    display: flex;
    flex-direction: row;
    height: 200px;
    margin: auto;

} */
.Artist_Product_Bottom_menu_artist_user{
    width: 15%;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    border-right: 1px solid #7979795e;
}


.Artist_Product_Bottom_menu_buttonGroup{
    display: flex;
    position: absolute;
    margin: auto;
    padding-top: 70px;
    padding-right: 30px;
    padding-left: 25px;
    flex-direction: row;
}





/* .card-img-wrapper_artist {
    position: absolute;
    height: 120px;
    width: 120px;
    
    display: flex;
    margin-top: 15px;
    margin-left: 10px;
    transform: scale(1.4);
    cursor: pointer;
}
 .card-img-wrapper_artist img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
  
} */
/* .card-title_artist {
    color: #2b2b2b;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
    position: absolute;
    display: block;
    margin-left: 76px;
    float: left;
    width: 100px;
    cursor: pointer;
  
    overflow: hidden;
    word-break:break-all;
    text-overflow:ellipsis; 
   white-space: nowrap;


   
} */




/* 
.Artist_Product_Bottom_menu_discrpction{
    color: #6e6e6e;
    display: block;
    position: relative;
    overflow: hidden;

    word-break:break-all;
    word-wrap: break-word;
    text-align: left;
    white-space: normal;
    display:-webkit-box;
    -webkit-line-clamp:4;
    -webkit-box-orient:vertical;
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
    width: 90%;
    margin-left:60px;
    margin-top: 8px;
    background-color: #ffffff;
} */

.Artist_Product_EDIT_button_enrollment{
    margin-left:60px;

}
.Artist_Product_discrpction_EDIT_button{
    margin-left:20px;

}
.ratingsize{
    transform: scale(1.5);
    margin: auto;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-top: 10px;
}




.favorite__Icon{
    transform: scale(0.9);
    padding-top: 0px;
    position: absolute;
   
}

/* 
.favorite__group{
    position: absolute;
    margin-left: 100px;
    padding-top: 105px;
    display: flex;
    flex-direction: row;
} */

.favorite__number{
    font-size: 15px;
    font-weight: 100;
    margin-left: 25px;
}


.Artist_Product_checkoutbox{  
    background-color: #fff;
    height: 90px;
    width: 190px;
    border-radius:10px;
    margin-top: 10px;
    cursor: pointer;
    display: flex;
}

.Artist_Product_checkoutbox :hover{
    background-color: #ba4148;
    border-radius:10px;
    transition: all 0.3s ease-out;
    color: #fff;
}
.Artist_Product_checkoutbox_text{
    text-align: center;
    font-weight: 600;
    font-size: 21px;
    width : 200px;
    line-height: 90px; 
}



/*   
  .PlayArrowIcon{  transform: scale(1.5); }
  .__Icon{transform: scale(1.3); }
  
  .__Icon:hover{color: #fff;}

  .playIcon_list__optionsIcon {
    color: rgb(255, 255, 255) !important;
    cursor: pointer;
    transform: scale(1.5);
  }
   */
  
  

  
