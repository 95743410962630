


@media only screen and (min-width: 1024px){

    .notice_search{
        width: 1200px;
        height: auto;
        border-collapse: collapse;
        text-align: left;
        margin: 10px auto;
        table-layout: fixed;
        cursor:default;
        outline:none;
    }   

.question_search_content{
    /* height: 200px; */
    justify-content: center;
    /* margin: auto; */
    /* display: flex; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
   
}


@media screen and (max-width:850px) {
   
    /* .navbar__center{margin-top: 130px; position: absolute;} */
}

