@media only screen and (min-width: 1367px) {
  #water-mark-observer{
    background-color: rgb(231, 36, 36);

  }
  .react-pdf{
    filter: blur(6px);
    -webkit-filter: blur(6px);
    -moz-filter: blur(6px); 
    -o-filter: blur(6px); 
    -ms-filter: blur(6px); 

    -webkit-user-select:none; 
    -moz-user-select:none;
     -ms-user-select:none; 
     user-select:none

  }
    .SheetPDF_all_button_wrapper {
        width: 100%;
        height: 60px;
        background-color: rgb(255, 255, 255);
        left: 0;
        right: 0;
        top: 0;
        line-height: 4rem;
        text-align: center;
        position: sticky;
        display: flex;
        flex-direction: row;
        overflow-x: hidden;
        overflow-y: hidden;
        border-bottom: 1px solid #7979795e;
        z-index: 1;
    }
    
    .SheetPDF_right_button_wrapper {
        position: relative;
        justify-content: flex-end;
        display: flex;
        align-items: flex-end;
        padding-right: 20px;
    }
    
    
    .SheetPDF_main_wrapper {
        display: flex;
        flex-direction: row;
        /* height: 720px; */
        overflow-y: hidden;
        overflow-x: hidden;
    
    }
    
    .pdf__optionsIcon {
        color: rgb(27, 27, 27) !important;
        cursor: pointer;
        transform: scale(1);
    }
    
    
    .pdf__Icon {
        transform: scale(1.2);
        
    }
    
    .pdf__Icon2 {
        transform: scale(1.0);
    }
}

@media screen and (max-width:1366px) and (min-width:916px) {

	}


@media screen and (max-width:915px) {

    .SheetPDF_all_button_wrapper {
        width: 100%;
        height: 60px;
        background-color: rgb(255, 255, 255);
        left: 0;
        right: 0;
        top: 0;
        /* line-height: 4rem; */
        text-align: center;
        position: sticky;
        display: flex;
        overflow-x: hidden;
        overflow-y: hidden;
        border-bottom: 1px solid #7979795e;
        z-index: 1;
        margin: auto;
        justify-content: center;
    }
    
    .SheetPDF_right_button_wrapper {
        position: relative;
        justify-content: flex-end;
        display: block;
        /* margin-right: auto; */
    }
    
    
    .SheetPDF_main_wrapper {
        display: flex;
        flex-direction: row;
        overflow-y: auto;
    
    
    }
    
    .pdf__optionsIcon {
        color: rgb(27, 27, 27) !important;
        cursor: pointer;
        transform: scale(1);
    }
    
    
    .pdf__Icon {
        transform: scale(1.2);
        
    }
    
    .pdf__Icon2 {
        transform: scale(1.0);
    }
    }


