

@media only screen and (min-width: 1367px) {
	.pcr-app:not(.visible) {
		display: none;
	}
	
	
	.navbar {
		box-sizing: border-box;
		max-height: 64px;
		background-color: #424242;
		margin-top: 00px;
		z-index: 1066;

	}
	.topContainer{
		/* max-height: 64px; */
		background-color: #424242;
		/* margin: auto; */
	}
	
	#minimizeMenu {
		position: absolute;
		right: 5px;
		transition: all 0.4s ease-out;
		cursor: pointer;
	}
	.zoomGroup {
		position: absolute;
		left: 0px;
		bottom: 0px;
	}
	.zoomGroup:hover,
	.pianoCanvas:hover ~ .zoomGroup {
		z-index: 1055;
	}
	canvas {
		/* z-index: -1; */
	}
	#progressBarCanvas {
		transition: all 0.4s ease-out;
		background-color: #565656;
		box-sizing: border-box;
		left: 0px;
		position: absolute;
		border-bottom: 4px solid #565656;
		float: left;
		cursor: pointer;
		z-index: 10;
		/* margin-top: 60px; */
	}
	
	.row {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
	}
	.forcedThinButton {
		width: 60px !important;
	}
	.innerMenuDivsContainer {
		position: relative;
		width: 100%;
		height: 100%;
		min-width: 1040px;
		background-color: rgba(0, 0, 0, 0);
		pointer-events: none;
		overflow: none;
		
	}
	.innerMenuDiv {
		margin-top: 24px;
		position: absolute;
		box-sizing: border-box;
		pointer-events: all;
		z-index: 100;
	
		width: 30%;
		height: 100%;
		min-width: 300px;
		right: 0px;
		padding: 5px;
	
		background-color: #424242;
		box-shadow: -3px 3px 10px 1px rgb(0 0 0, 0.5);
	
		overflow-y: auto;
		overflow-x: hidden;
	
		transition: all 0.25s ease-out;
	}
	.innerMenuDiv.hidden {
		/* right: -30%; */
	}
	
	.innerMenuContDiv:first-of-type {
		border-top: none;
	}
	.innerMenuContDiv {
		background-color: #424242;
		background-image: #424242;
		color: #424242;
		border-top: 1px solid var(--borderColor);
		border-radius: 2px;
		padding: 5px;
		margin: 5px;
		box-sizing: border-box;
	
	}
	.centeredMenuDiv {
		background-color: #424242;
		max-height: calc(50% - 50px);
		top: 24px;
		padding: 2em;
		position: absolute;
		width: 50%;
		left: 25%;
		overflow-y: auto;
		overflow-x: hidden;
		border-radius: 5px;
		transition: all 0.3s ease-out;
	}
	.notification {
		background-color: #424242;
		max-height: calc(50% - 50px);
		top: 30%;
		padding: 30px;
		position: absolute;
		width: 50%;
		left: 25%;
		overflow-y: auto;
		overflow-x: hidden;
		border-radius: 5px;
	}
	.highlighted {
		animation: pulse 1.5s infinite;
		box-shadow: 0em 0em 5px 5px rgba(0, 0, 0, 0.8);
	}
	
	@-webkit-keyframes pulse {
		0% {
			-webkit-box-shadow: 0 0 0 0 rgba(10, 141, 228, 0.4);
		}
		50% {
			-webkit-box-shadow: 0 0 0 10px rgba(10, 141, 228, 0.4);
		}
		100% {
			-webkit-box-shadow: 0 0 0 0 rgba(10, 141, 228, 0.4);
		}
	}
	@keyframes pulse {
		0% {
			-moz-box-shadow: 0 0 0 0 rgba(10, 141, 228, 0.4);
			box-shadow: 0 0 0 0 rgba(10, 141, 228, 0.4);
		}
		50% {
			-moz-box-shadow: 0 0 0 10px rgba(10, 141, 228, 0.4);
			box-shadow: 0 0 0 10px rgba(10, 141, 228, 0.4);
		}
		100% {
			-moz-box-shadow: 0 0 0 0 rgba(10, 141, 228, 0.4);
			box-shadow: 0 0 0 0 rgba(10, 141, 228, 0.4);
		}
	}
	
	.centeredBigText {
		width: 100%;
		text-align: center;
	}
	.trackName {
		width: 100%;
		text-align: l;
	}
	.instrumentName {
		width: 100%;
		text-align: left;
		font-size: 0.7em;
	}
	.divider {
		border: 0;
		border: 1px solid var(--buttonColor);
	}
	
	
	
	.sliderLabel,
	.checkboxlabel {
		text-align: center;
		color: #424242a3;
		z-index: 15;
		pointer-events: none;
		font-size: 0.75rem;
		margin-bottom: -5px;
	}
	.sliderVal {
		float: left;
		color: #424242a3;
		z-index: 15;
		pointer-events: none;
		font-size: 0.75rem;
		margin-bottom: -5px;
	}
	input[type="range"] {
		-webkit-appearance: none;
		margin: 10px 0;
		width: 100%;
		padding-left: 1px;
		padding-right: 1px;
		transition: all 1s ease-out;
	}
	
	
	
	
	
	
	/* .glyphicon{
		transform: scale(5);
	}
	 */
	
	
	
	
	
	
	
	.floatSpanLeft span {
		float: left;
		margin-right: 2px;
	}
	.container5 {
		display: flex;
		height: 62px;
	}
	.row {
		width: 100%;
		margin: 0;
	}
	.col {
		justify-content: space-between !important;
	}
	.btn-group {
		align-self: auto;
	}
	.btn-group-vertical {
		display: flex;
		flex-direction: row;
		margin-top: 15px;
	}
	.glyphicon-volume-up{margin-bottom: 10px;}
	.btn {
		position: relative;
		box-sizing: border-box;
	
		padding: 0.2em;

		/* padding-left: 1em; */
		/* padding-right: 1em; */
		text-align: center;
		font-size: 1em;
		font-weight: normal !important;
	
		color: #bdbdbd;
		/* background-color: #494949; */
		background-color: #49494900;
		outline: none;
		border-radius: 1px;
		border: 1px solid rgba(0, 0, 0, 0);
		cursor: pointer;
		transition: all 0.15s ease-out;
		z-index: 100;
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none;
	}
	.btn:hover:active {
		background-color: #424242;
	}
	.btn:hover {
		background-color: #424242;
		color: #424242;
		border: 1px solid #424242;
	}
	.btn-select-line {
		border-bottom: 4px solid #607d8b;
		transition: all 0.15s ease-out;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 0%;
		opacity: 0;
	}
	.btn.selected {
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
	}
	.btn.selected .btn-select-line {
		opacity: 1;
		width: 100%;
	}
	.btn-lg {
		font-size: 1.5em;
		margin-left: 0.4em;
		margin-right: 0.4em;
		color: aqua;
	}
	.btn span {
		font-size: 1em;
	}
	.pcr-button {
		opacity: 0;
		position: absolute;
	}
	
	.topContainer {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 30;
		/* margin-bottom: 50px; */
		/* height:62px; */
		/* margin-bottom: 320px; */
	}
	
	.topContainer:first-child {
		margin-right: auto;
		justify-content: space-between !important;
		align-items: unset;
		
	}
	
	.topContainer:last-child {
		margin-left: auto;
		justify-content: space-between !important;
		align-items: unset;
	}
	
	.vertical-align {
		display: flex;
		align-items: center;
	}
	.hidden {
		visibility: hidden;
		opacity: 0;
	}
	.unhidden {
		visibility: visible;
		opacity: 1;
	}
	.fullscreen {
		/* position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%; */
	}
	.fullscreen p {
		/* color: var(--inputBgColor);
		text-align: center;
		position: fixed;
		z-index: 999;
		overflow: show;
		margin: auto;
		top: 11.5em;
		font-size: 1.5em;
		left: 0;
		bottom: 0;
		right: 0;
		height: 50px; */
	}
	
	.floatLeft {
		float: left;
	}
	.loadingDiv {
		text-align: left;
	}
	/* Loading Spinner */
	.loader,
	.loader:after {
		position: fixed;
		z-index: 999;
		overflow: show;
	
		top: 380px;
		left: 660px;
		bottom: 0;
		right: -30px;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		width: 10em;
		height: 10em;
	}
	
	
	.loader {
		font-size: 0.5em;
		border-top: 1.1em solid rgba(255, 255, 255, 0.2);
		border-right: 1.1em solid rgba(255, 255, 255, 0.2);
		border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
		border-left: 1.1em solid #ffffff;
		-webkit-transform: translateZ(0);
		-ms-transform: translateZ(0);
		transform: translateZ(0);
		-webkit-animation: load8 1.1s infinite linear;
		animation: load8 1.1s infinite linear;
	}
	@-webkit-keyframes load8 {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@keyframes load8 {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	

}
@media screen and (max-width:915px) {
	.pcr-app:not(.visible) {
		display: none;
	}
	
	
	.navbar {
		box-sizing: border-box;
		background-color: #cecece;
		margin-top: 00px;
		/* width: 1500px;[] */
		z-index: 1066;

	}
	.topContainer{
		background-color: #ba4148;
	}
	
	#minimizeMenu {
		position: absolute;
		right: 5px;
		transition: all 0.4s ease-out;
		cursor: pointer;
	}
	.zoomGroup {
		position: absolute;
		left: 0px;
		bottom: 0px;
	}
	.zoomGroup:hover,
	.pianoCanvas:hover ~ .zoomGroup {
		z-index: 1055;
	}
	canvas {
		/* z-index: -1; */
	}
	#progressBarCanvas {
		transition: all 0.4s ease-out;
		background-color: #424242;
		box-sizing: border-box;
		left: 0px;
		position: absolute;
		border-bottom: 4px solid #424242;
		float: left;
		cursor: pointer;
		z-index: 10;
		top: -40px;
		/* margin-top: 60px; */
	}
	#speedUp{
		display: none;
	}
	#mute{
		display: none;

	}
	#volumeMain{
		display: none;

	}
	.forcedThinButton{
		display: none;

	}
	.row {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
	}
	.forcedThinButton {
		width: 60px !important;
	}
	.innerMenuDivsContainer {
		position: relative;
		width: 100%;
		height: 100%;
		/* min-width: 1040px; */
		background-color: rgba(0, 0, 0, 0);
		pointer-events: none;
		overflow: none;
		
	}
	.innerMenuDiv {
		margin-top: 24px;
		position: absolute;
		box-sizing: border-box;
		pointer-events: all;
		z-index: 100;
	
		width: 30%;
		height: 100%;
		min-width: 300px;
		right: 0px;
		padding: 5px;
	
		background-color: #424242;
		box-shadow: -3px 3px 10px 1px rgb(0 0 0, 0.5);
	
		overflow-y: auto;
		overflow-x: hidden;
	
		transition: all 0.25s ease-out;
	}
	.innerMenuDiv.hidden {
		/* right: -30%; */
	}
	
	.innerMenuContDiv:first-of-type {
		border-top: none;
	}
	.innerMenuContDiv {
		background-color: #424242;
		background-image: #424242;
		color: #424242;
		border-top: 1px solid var(--borderColor);
		border-radius: 2px;
		padding: 5px;
		margin: 5px;
		box-sizing: border-box;
	
	}
	.centeredMenuDiv {
		background-color: #424242;
		max-height: calc(50% - 50px);
		top: 24px;
		padding: 2em;
		position: absolute;
		width: 50%;
		left: 25%;
		overflow-y: auto;
		overflow-x: hidden;
		border-radius: 5px;
		transition: all 0.3s ease-out;
	}
	.notification {
		background-color: #424242;
		max-height: calc(50% - 50px);
		top: 30%;
		padding: 30px;
		position: absolute;
		width: 50%;
		left: 25%;
		overflow-y: auto;
		overflow-x: hidden;
		border-radius: 5px;
	}
	.highlighted {
		animation: pulse 1.5s infinite;
		box-shadow: 0em 0em 5px 5px rgba(0, 0, 0, 0.8);
	}
	
	@-webkit-keyframes pulse {
		0% {
			-webkit-box-shadow: 0 0 0 0 rgba(10, 141, 228, 0.4);
		}
		50% {
			-webkit-box-shadow: 0 0 0 10px rgba(10, 141, 228, 0.4);
		}
		100% {
			-webkit-box-shadow: 0 0 0 0 rgba(10, 141, 228, 0.4);
		}
	}
	@keyframes pulse {
		0% {
			-moz-box-shadow: 0 0 0 0 rgba(10, 141, 228, 0.4);
			box-shadow: 0 0 0 0 rgba(10, 141, 228, 0.4);
		}
		50% {
			-moz-box-shadow: 0 0 0 10px rgba(10, 141, 228, 0.4);
			box-shadow: 0 0 0 10px rgba(10, 141, 228, 0.4);
		}
		100% {
			-moz-box-shadow: 0 0 0 0 rgba(10, 141, 228, 0.4);
			box-shadow: 0 0 0 0 rgba(10, 141, 228, 0.4);
		}
	}
	
	.centeredBigText {
		width: 100%;
		text-align: center;
	}
	.trackName {
		width: 100%;
		text-align: l;
	}
	.instrumentName {
		width: 100%;
		text-align: left;
		font-size: 0.7em;
	}
	.divider {
		border: 0;
		border: 1px solid var(--buttonColor);
	}
	
	
	
	.sliderLabel,
	.checkboxlabel {
		text-align: center;
		color: #424242a3;
		z-index: 15;
		pointer-events: none;
		font-size: 0.75rem;
		margin-bottom: -5px;
	}
	.sliderVal {
		float: left;
		color: #424242a3;
		z-index: 15;
		pointer-events: none;
		font-size: 0.75rem;
		margin-bottom: -5px;
	}
	input[type="range"] {
		-webkit-appearance: none;
		margin: 10px 0;
		width: 100%;
		padding-left: 1px;
		padding-right: 1px;
		transition: all 1s ease-out;
	}
	

	
	.floatSpanLeft span {
		float: left;
		margin-right: 2px;
	}
	.container5 {
		width: 100% !important;
		display: flex;
		height: 62px;
	}
	.row {
		width: 100%;
		margin: 0;
	}
	.col {
		justify-content: space-between !important;
	}
	.btn-group {
		align-self: auto;
	}
	.btn-group-vertical {
		display: flex;
		flex-direction: row;
		margin-top: 15px;
	}
	.glyphicon-volume-up{margin-bottom: 10px;}
	.btn {
		position: relative;
		box-sizing: border-box;
		transform: scale(0.7);
		padding: 0.2em;
	
		text-align: center;
		font-size: 1em;
		font-weight: normal !important;
	
		color: #b6b6b6;
		background-color: #ffffff00;
	
		outline: none;
		border-radius: 1px;
		border: 1px solid rgba(0, 0, 0, 0);
		cursor: pointer;
		transition: all 0.15s ease-out;
		z-index: 100;
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none;
	}
	.btn:hover:active {
		background-color: #424242;
	}
	.btn:hover {
		background-color: #424242;
		color: #424242;
		border: 1px solid #424242;
	}
	.btn-select-line {
		border-bottom: 4px solid #607d8b;
		transition: all 0.15s ease-out;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 0%;
		opacity: 0;
	}
	.btn.selected {
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
	}
	.btn.selected .btn-select-line {
		opacity: 1;
		width: 100%;
	}
	.btn-lg {
		font-size: 1.5em;
		margin-left: 0.2em;
		margin-right: 0.2em;
		color: aqua;
	}
	.btn span {
		font-size: 1em;
	}
	.pcr-button {
		opacity: 0;
		position: absolute;
	}
	
	.topContainer {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 30;
		/* margin-bottom: 50px; */
		height:42px;
		/* margin-bottom: 320px; */
	}
	
	.topContainer:first-child {
		margin-right: auto;
		justify-content: space-between !important;
		align-items: unset;
		
	}
	
	.topContainer:last-child {
		margin-left: auto;
		justify-content: space-between !important;
		align-items: unset;
	}
	
	.vertical-align {
		display: flex;
		align-items: center;
	}
	.hidden {
		visibility: hidden;
		opacity: 0;
	}
	.unhidden {
		visibility: visible;
		opacity: 1;
	}
	.fullscreen {
		/* position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%; */
	}
	.fullscreen p {
		/* color: var(--inputBgColor);
		text-align: center;
		position: fixed;
		z-index: 999;
		overflow: show;
		margin: auto;
		top: 11.5em;
		font-size: 1.5em;
		left: 0;
		bottom: 0;
		right: 0;
		height: 50px; */
	}
	
	.floatLeft {
		float: left;
	}
	.loadingDiv {
		text-align: left;
	}
	/* Loading Spinner */
	.loader,
	.loader:after {
		position: fixed;
		z-index: 999;
		overflow: show;
	
		top: 380px;
		left: 660px;
		bottom: 0;
		right: -30px;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		width: 10em;
		height: 10em;
	}
	
	
	.loader {
		font-size: 0.5em;
		border-top: 1.1em solid rgba(255, 255, 255, 0.2);
		border-right: 1.1em solid rgba(255, 255, 255, 0.2);
		border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
		border-left: 1.1em solid #ffffff;
		-webkit-transform: translateZ(0);
		-ms-transform: translateZ(0);
		transform: translateZ(0);
		-webkit-animation: load8 1.1s infinite linear;
		animation: load8 1.1s infinite linear;
	}
	@-webkit-keyframes load8 {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@keyframes load8 {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	

}
