@media only screen and (min-width: 1024px){


.AvatarModalContainer_wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index:30;

}
.AvatarModalContainer_background_white {
  width: 480px;
  height: 600px;
  background-color: white;
  position: relative;

  margin: 120px auto;
  padding: 20px;
  background: #fff;
  border-radius: 15px;    

/* justify-content: center;
align-items: center; */
}
.AvatarModalContainer_content{
  justify-content: center;
align-items: center;
 display: flex;
 flex-direction: column;

 height: 580px;
}
/* 맨윗줄 */
.AvatarModalContainer_content_Signup_close{cursor: pointer;}
.AvatarModalContainer_content_Signup_content{ width: 400px;  padding-top: 15px;  flex-direction: row;    display: flex; justify-content: space-between; }
.AvatarModalContainer_content_Signup_text{  flex-direction: row;  display: flex;}


.AvatarModalContainer_content_input_content {
  margin: 0 auto;
  width: 100%;
  position: relative;
  padding: 10px 20px 32px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
}


.AvatarModalContainer_avatar{
  background-color: transparent;
  z-index: 10;
  
}
.AvatarModalContainer_avatar_Preview{
  display: flex;
  justify-content: center;
}
.AvatarModalContainer_PHOTO_PREVIEW{
  width: 120px;
  height: 120px;
  border-radius: 120px;
  margin-top: 20px;
  /* align-items: ; */
}

}







