


@media only screen and (min-width: 1024px){

    .Question_song__image{
        min-width: 90px;
        max-width:90px;
        min-height: 90px;
        max-height: 90px;
        object-fit: cover;
        align-items: center;
        background-color: rgba(224, 224, 224, 0.226);
    }
}


@media screen and (max-width:850px) {
   
    /* .navbar__center{margin-top: 130px; position: absolute;} */
}

