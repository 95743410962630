@media only screen and (min-width: 1024px){


.ModalContainer_wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index:30;

}
.ModalContainer_background_white {
  width: 480px;
  height: 600px;
  background-color: white;
  position: relative;

  margin: 120px auto;
  padding: 20px;
  background: #fff;
  border-radius: 15px;    

/* justify-content: center;
align-items: center; */
}
.ModalContainer_content{
  justify-content: center;
align-items: center;
 display: flex;
 flex-direction: column;

 height: 580px;
}
/* 맨윗줄 */
.ModalContainer_content_Signup_close{cursor: pointer;}
.ModalContainer_content_Signup_content{ width: 400px;  padding-top: 15px;  flex-direction: row;    display: flex; justify-content: space-between; }
.ModalContainer_content_Signup_text{  flex-direction: row;  display: flex;}


.ModalContainer_content_input_content {
  margin: 0 auto;
  width: 100%;
  position: relative;
  padding: 10px 20px 32px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ModalContainer_content_input_checkbox{
width: 100%;
margin: 15px 0;
padding: 0;
box-sizing: border-box;
display: flex;
justify-content: flex-start;
gap: 30px;
color: rgba(0, 0, 0, 0.5);
}



.ModalContainer_SocialBox_SubmitInput{
  background-color: #ba4148;
          border-color: #ba4148;
          height: 52px;
          display: flex;
          justify-content: center;
          box-sizing: border-box;
          color: #fff;
          border-radius: 12px;
          margin-top: 0px;
          font-weight: 600;
            font-size: 21px;
            cursor: pointer;
            width: 400px;
} 


.ModalContainer_SocialBox_Logo {
  padding: 0;
  width: 20px;
  height: 20px;
  margin-right: 10px;

}

.ModalContainer_autoLogin {
  font-size: 12px;
  color: #8d8d8d;
  line-height: 3;
}

.ModalContainer_info_checkbox_signup{
  height: 85px;
  width: 400px;
  border: 1px solid #aaaaaa65;
  border-radius: 15px;
  margin-top: 14px;
}


.ModalContainer_info_checkbox_signup_emphasis{
  font-weight: 600;
  color: rgb(71, 71, 71);
  margin-left: 4px;
}



.ModalContainer_SocialBox_signup_SubmitInput {
  background-color: #ba4148;
  border-color: #ba4148;
  height: 80px;
  display: flex;
  justify-content: center;
  color: #fff;
  border-radius: 12px;
  margin-top: 6px;
  margin-left: 16px;
  cursor: pointer;
  width: 150px;
  text-align: center;
  font-weight: 600;
  font-size: 21px;
}

}








 

        .err_login_font{
          color: #f3f3f3;
          font-size: 21px;
    
          /* margin-left: 12px; */
          width: 400px;
          height: 65px;
          background-color: #ba4148;
        }
        
        .err_login_font .err_font{
          line-height: 65px;
          text-align: center;
          position: absolute;
          width:400px; left:0; right:0; margin-left:auto; margin-right:auto;

        }