


@media only screen and (min-width: 1024px){

    .notice-table{
        width: 1200px;
    }    

    .NoticeWrite_select{
        width: 200px;
        margin-top: 10px;
    }
}


@media screen and (max-width:850px) {
   
    /* .navbar__center{margin-top: 130px; position: absolute;} */
}

