


@media only screen and (min-width: 1024px){

.wrapper{
    width:1800px;
    height: 500px;
    background-color: aqua;
    margin-top: 64px;
    cursor: pointer;
}

.menuBar{
    /* padding: 1rem; */
    background: #ffffff;
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 64px;
    height: 64px;
    border-bottom: 0.5px solid #dbdbdba1;
}
.tabs li{
    font-size: 1rem;
    font-weight: bold;
    display: inline-block;
    color: #202020;
    /* color: #74747459; */

    padding: 1rem;
    margin-top: 5px;
}
.tabs{
    cursor: pointer;
    margin: auto;
    width: 400px;
    text-align: center;
    justify-content: space-between;
    display: flex;
}
.tabs li.active{
    background-color: #6f03b7;
    height: 100%;
}

}


@media screen and (max-width:850px) {
   
    /* .navbar__center{margin-top: 130px; position: absolute;} */
}