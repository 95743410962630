@media only screen and (min-width: 1024px){
    .product_upload_dashboard{overflow: visible; }
    .product_upload_side_container{
        display: flex;
        margin: 0 auto;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 10px;
        overflow:visible;
        
    }
     

    .card_Product_upload  {
        position: relative;
        display: inline-block;
        /* background: linear-gradient( #fcd68f 25%, #fff 10% ); */
        border-radius: 15px;
        box-shadow: 2px 8px 12px #1414140a, 0 1px 3px #14141405, 0 0 0 1px #00000005;
        text-align: center;
        /* padding-top: 35px; */
        transition: all 0.5s;
        /* height: 500px; */
        width: 98%;
        margin-left: 1%;
        margin-right: 1%;
        overflow: hidden;
        /* padding-bottom: 1000px; */
    }

    .mainCircle{
        background-color: rgb(223, 223, 223);
        width:140px;
        height:140px;
        border-radius: 140px;
        left:730px;
        margin-top: 10px;
        align-items: center;
        font-size: 33px;
        font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 600;
        color: rgb(39, 39, 39);
        line-height: 140px;
        position: absolute;
        z-index: 1;
    
    }

    
    .t_style {font-size:12px;   font-family: Tahoma,  sans-serif;   text-align:left; }
    .t_name {font-size:13px; font-family: Tahoma, sans-serif; color: #333; font-weight:600; letter-spacing:-0.3pt; background: #fff !important;  padding-left:0px; width: 150px;   text-align: center;       line-height: 70px;   border-right: 1px solid #cecece; margin-right: 30px; padding-right: 30px; display: inline-block;}
    .t_value {font-size:13px; font-family: Tahoma,  sans-serif; color: #555555; background: #ffffff !important; text-align:left;  line-height:65px;  display: flex; flex-direction: row; width: 100%;}

    .boxIn_first{ 
        flex-direction: row; 
        display: flex;
        text-align: center;  
        height: 60px; 
        margin-left: 20px;
    }
        
    .boxIn{ 
        flex-direction: row; 
        display: flex;
        text-align: center;  
        height: 60px; 
        margin-top: 28px;
        margin-left: 20px;
    }

    .product_upload_search{ 
        color: rgb(68, 68, 68) !important;
        position: absolute;
        top: 115px;
        left: 43.5%;
        background-color: transparent;
        background-size: cover;
        background-position: center;
        margin: 0;
        cursor: pointer;
        transform: scale(1.2);
    }
    .product_upload_search2{ 
        color: rgb(68, 68, 68) !important;
        position: absolute;
        top: 200px;
        left: 43.5%;;
        background-color: transparent;
        background-size: cover;
        background-position: center;
        margin: 0;
        cursor: pointer;
        transform: scale(1.2);
    }
    .genre_option{
        padding: 10px;
        /* margin-top: ; */
    }
    .filebox .filebox3{
        display: inline-block;
        padding: .5em .75em;
        color: #fff;
        font-size: inherit;
        line-height: normal;
        vertical-align: middle;
        background-color: #ba4148  ;
        cursor: pointer;
        border: 1px solid #ba4148  ;
        border-radius: .25em;
        width: 90%;
        text-align: center;
        -webkit-transition: background-color 0.2s;
        transition: background-color 0.2s;
      }
      .filebox .filebox2{
        display: inline-block;
        padding: .5em .75em;
        color: #fff;
        font-size: inherit;
        line-height: normal;
        vertical-align: middle;
        background-color: rgb(199, 199, 199);
        cursor: pointer;
        border: 1px solid rgb(199, 199, 199);
        border-radius: .25em;
        width: 90%;
        text-align: center;
        -webkit-transition: background-color 0.2s;
        transition: background-color 0.2s;
      }
    
    
      .filebox label:hover {
        background-color: #ba4148;
      }
      
      .filebox label:active {
        background-color: #fff;
      }
      
      .filebox input[type="file"] {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
      }


      .filebox_JPG .filebox3{
        display: inline-block;
        padding: .5em .75em;
        color: #fff;
        font-size: inherit;
        line-height: normal;
        vertical-align: middle;
        background-color: #ba4148 ;
        cursor: pointer;
        border: 1px solid #ba4148 ;
        border-radius: .25em;
        width: 300px;
        text-align: center;
        -webkit-transition: background-color 0.2s;
        transition: background-color 0.2s;
        margin-top: 20px;
      }
      .filebox_JPG .filebox2{
        display: inline-block;
        padding: .5em .75em;
        color: #fff;
        font-size: inherit;
        line-height: normal;
        vertical-align: middle;
        background-color: rgb(199, 199, 199);
        cursor: pointer;
        border: 1px solid rgb(199, 199, 199);
        border-radius: .25em;
        width: 300px;
        text-align: center;
        -webkit-transition: background-color 0.2s;
        transition: background-color 0.2s;
        margin-top: 20px;
      }
    
    
      .filebox_JPG label:hover {
        background-color: #dd7f84;
      }
      
      .filebox_JPG label:active {
        background-color: #fff;
      }
      
      .filebox_JPG input[type="file"] {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
      }





      .t_Subvalue{display: flex; flex-direction: column;}
      .fileNameInput{
        font-size: 12px;
        font-weight: 300;
        font-family: Arial, Helvetica, sans-serif;
        margin-left: 8px;
        line-height: 52px;
        color: #414141
    }
    
    .sub_tit_alt2 { margin-left: 8px; margin-top: 12px;  font-size: 12px; color: #adadad; font-family:  Tahoma,  sans-serif; position: absolute;}

    .some{
        border: 1px solid #0000003f;
        font-size: 21px;
        margin-top: 20px;
        margin-left: 10px;
        position: relative;
        display: flex;
        border-radius: 5px;
        height: 60px;
        padding-left: 20px;
        width: 100%;
       /* padding-bottom: 23px; */
        /* text-align: right; */
        /* padding-top: 12px; */
        /* padding-bottom: 10px; */
      }
      .songNameImage{
        /* margin-top: 30px; */
        /* margin: auto; */
        align-items: center;
        font-size: 15px;
        text-align: center;
        font-family:Arial, Helvetica, sans-serif;
        font-weight: 400;
        width: 100%;
        height: 40px;
        padding-left: 12px;
        padding-right: 12px;
        /* padding-top: 35px; */
        word-break:break-all;
        text-overflow:ellipsis;
        overflow:hidden;
        /* left: 500px; */
        /* margin-left: 90px; */
        line-height: 21px;
        display:-webkit-box;
        -webkit-line-clamp:2;
        -webkit-box-orient:vertical;
        position: relative;
        opacity: 0.7;
    }
    .pdfnextpage{margin-left: 5px;}

    

    .sheetfileinfo_main{object-fit: cover; width: 100%; height: 320px; padding-left: 10px; border: 1px solid #fff; opacity: 0.3;}
    .spotifyimage_main{
        object-fit:cover;
        width: 240px;
        height: 240px;
        margin: auto;
     
        /* margin-top: 16px; */
      
    }

}
