
 
.checkout_right_content_card_text{
    display: flex;
    width: 70%;
    line-height: 40px;
}
.checkout_right_content_fold_payment_content {
    overflow: hidden;
    background-color: rgb(255, 255, 255);
    transition: max-height 0.3s ease-out; 
    width: 90%;
    margin: auto;
    max-height: 0;
  }
 

.checkout_right_content_fold_payment{
    color: #fff;
    text-align: center;
    font-weight: 600;
    font-size: 21px;
    background-color: #999999;
    padding-top: 10px;
    height: 50px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 12px;
    margin: auto;
    width: 90%;
    cursor: pointer;
}


.checkout__left_table{
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    margin: auto;
    table-layout: fixed;
    margin-top: 20px;
}


.checkout__left_thead{
    border-bottom: 1px solid #97979754;
    border-top: 1px solid #97979754;
    padding-top: 20px;
    padding-bottom: 20px;
}


.checkout__left_content_product{
    height: 60px;
    font-size: 18px;
    font-weight: 600;
}
.checkout__left_content_price{
    height: 60px;
    font-size: 18px;
    font-weight: 600;
}

.Payment_checkout__left_content_tbody{
    border-bottom: 1px solid #97979754;
    text-align: center;
    width: 100%;
}

.checkout__left_content_tbody_image{
    cursor: pointer;
    min-width: 85px;
    max-width:85px;
    min-height: 85px;
    max-height: 85px;
    object-fit: cover;
    align-items: center;
    transition: 0.3s ease;
    position: relative;
    padding: 10px;
    margin-left: 40px;
}

.Payment_checkout__left_content_song_artist{ display:flex; flex-direction: row; cursor: pointer;  position:relative;    text-overflow:ellipsis;   word-break:break-all;  overflow:hidden;  text-align: left;   line-height: 24px; }   

.Payment_checkout__left_content_tbody_songName{
    flex-direction: column;
    display: flex;
    text-align: left;
    justify-content: center;
    line-height: 21px;
    font-size: 15px;
    color: #666666;
    overflow:hidden;
    white-space : nowrap;
    text-overflow: ellipsis;
    margin-left: 10px;
}
.Payment_checkout__left_content_song_artist{  
    cursor: pointer; 
    position:relative;   
    text-align: left; 
    height: 80px;

    line-height: 20px;
	overflow:hidden;
    word-break:break-all;
    text-overflow:ellipsis;
    white-space : normal; /*기본값*/
    text-overflow: clip; /*기본값*/
        }   


.Payment_checkout__left_content_tbody_artistname{  position: relative;  font-size: 10px;}


.checkout__left_content_tbody_price{
    text-align: center;
    color: rgb(26, 26, 26);
}

.checkout__left_content_tbody_total{
    width: 100%;
    border-bottom: 1px solid #97979754;
    height: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 21px;

}


.checkout__right_content_card {
    position: relative;
    display: inline-block;
    background:  #fff ;
    border-radius: 15px;
    box-shadow:  0 4px 6px rgba(129, 129, 129, 0.16);
    text-align: center;
    padding-top: 10px;
    transition: all 0.5s;
    height: 700px;
    width: 80%;
  


}

.checkout__right_content_card_total{
    width: 80%;
    margin: 10px auto;
    font-size: 30px;
    text-align: left;
    border-bottom: 1px solid #97979754;
 
}
.checkout__right_content_card_total_price{
    width: 80%;
    margin: auto;
    font-size: 30px;
    text-align: left;
    border-bottom: 1px solid #97979754;
    justify-content: space-between;
    display: flex;
    height: 80px;
    margin-top: 40px;
}



