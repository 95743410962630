


@media only screen and (min-width: 1367px){

    .Header_wrapper{
        top: 0;
        left: 0;
        right: 0;
        z-index: 999;
        max-width: 100%;
        width: 100%;
        height: 64px;
        position: sticky;
        align-items: center;
        background-color:#ba4148;
    }

    .ReactModalPortal{ z-index: 1000;position: sticky;}
    .slide-pane__header{background-color:#ffffff;} 

    .menuList_Tap{
        position: fixed;
        top :50px; 
         width: 200px;
         left: 70%;
     }
    .menu_svgIcon{
           color: rgb(255, 255, 255) !important; 
           transform: scale(1.4) !important;
      }

      .hamburger_menu li{
        text-decoration: none;
        list-style:none;
        margin-top: 5px;
        margin-bottom: 5px;
      }
      .hamburger_menu a{
        text-decoration: none;
        list-style:none;
        color: rgb(59, 59, 59);
    }
  
    .Header_wrapper_center_margin{
        display: flex;
        flex-direction: row;
        margin: 0 auto;
       position: relative;
       justify-content: center;
    }
    

    .Header_logo{
        width: 45px;
        height: 45px;
        margin-top: 14px;
        position: absolute;
        left: -44px;
    }

    .Header_menu {
        position: relative;
        flex-direction: row;
        display: flex;
    }
    
    .Header_menu a {
        display: block;
        white-space: nowrap;
        text-decoration: none;
    }
    
    .Header_menu li {
        list-style-type: none
    }

    .Header_menu_login{
        position: relative;
        flex-direction: row;
        display: flex;
        margin-left: 10px;
    }
    .Header_menu_login li{
        list-style-type: none
    }
    .Header_menu_navbar { padding-left: 20px;     display: flex;
    }
       
       

    .Header_menu_top_right_menu a:link, 
    .Header_menu_top_right_menu a:visited { z-index:-100; display:block; padding:0 28px; line-height:64px; font-size:18px; color:rgb(255, 255, 255); font-weight:500; letter-spacing:0pt;   font-family: Arial, Helvetica, sans-serif;  font-weight:500;   transition: all 0.2s ease;}
    .Header_menu_top_right_menu a:hover{color:rgb(252, 140, 140); text-decoration:none; }
    .Header_menu_top_right_menu span{ cursor: pointer;  background-color: transparent; z-index:-100; display:block; padding:0 28px; line-height:64px; font-size:18px; color:rgb(255, 255, 255); font-weight:500; letter-spacing:0pt;   font-family: Arial, Helvetica, sans-serif;  font-weight:500;   transition: all 0.2s ease;}
    .Header_menu_top_right_menu span:hover{ cursor: pointer;  color:rgb(252, 140, 140); text-decoration:none; }
   
    /* 2차 ul */
    .Header_menu div {visibility:hidden; position:absolute; z-index:100; }
    .Header_menu li:hover>div { visibility:visible;    transition: transform 0.6s cubic-bezier(0.83, 0, 0.17, 1);  transform: translate3d(0, 15%, 0);}  
    .Header_menu li li { color:#666; letter-spacing:-0.5pt; padding:3px 15px; text-align:left; font-size:14px; white-space:normal; cursor: pointer;}
    .Header_menu li li :hover{ color:#ec202a;}
    .Header_menu li li:first-child {padding-top:20px;}
    .Header_menu li li:last-child  {padding-bottom:20px;}
    /* 기본 */
    .Header_menu_depth {visibility:hidden; position:absolute; width:150px; }
    .Header_menu_depth ul {margin-top:-40px; box-shadow:0px 0px 10px rgba(102,102,102,0.2); background:rgba(255,255,255,0.95 ); box-sizing:border-box; z-index:100 !important; border-radius:5px;}
    .Header_menu_depth a { color:#666; letter-spacing:-0.5pt; text-align:left; font-size:14px; white-space:normal;}
   
    .Header_menu_top_right_user_displayName{ cursor: pointer;  background-color: transparent; z-index:-100; display:block; padding:0 10px; line-height:18px; font-size:18px; color:rgb(255, 255, 255); font-weight:500; letter-spacing:0pt;   font-family: Arial, Helvetica, sans-serif;  font-weight:500;   transition: all 0.2s ease;}
    .Header_menu_top_right_user_displayName span:hover{ cursor: pointer;  color:rgb(252, 140, 140); text-decoration:none; }
    

   

    .Header_menu_search_wrap{
        display: flex;   
         background-color: transparent;  
          transition: all 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
          height: 64px;
        align-items: center;
        position: relative;
        margin-left: 2%;
        flex-direction: row;
    }
     
    .Header_menu_search{
        background-color: rgba(255, 255, 255, 0.007);
        width: 450px; 
        height: 40px;
        font-size: 14px;
        font-weight: 500;
        padding: 0 0px 0 15px;
        transition: all 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
        border: 2.5px solid rgb(255, 255, 255);
        border-radius: 20px;
        font-size:12pt;
         color:rgb(255, 255, 255); 
    }
   
       #search:focus {
        outline: none;
        /* border: 2.5px solid rgb(255, 255, 255); */
      }
      #search::placeholder {
        color: rgb(255, 255, 255);
        font-weight: 500;
        font-size: 14px;
      }
      
    .search__barSearchIcon{ 
        color: rgb(255, 255, 255) !important;
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: transparent;
        background-size: cover;
        background-position: center;
        margin: 0;
        cursor: pointer;
    }

    .Header_menu_center_right{
            display: flex; 
            background-color: transparent; 
            height: 64px;
            align-items: center;
            position: relative;
            margin-left: 40px;
            flex-direction: row;
    }


.languge_svgIcon{
    position: absolute;
       top: 20px;
       left: -10px;
       margin: 0;
       color: rgb(255, 255, 255) !important; 
       transform: scale(1.1) !important;
       /* padding: 2px; */


}

.navbar__avatar{
    position: absolute;
    top: 0px;
    left: 5px;
    margin: 0;
    color: rgb(255, 255, 255) !important;
    transform: scale(0.8) !important; 
}


}





@media screen and (max-width:1366px) and (min-width:916px) {

    /* .Header_menu_top_menu a:visited { z-index:-100; } */


    .Header_menu_Login_font{
        display:block; 
        padding:0 12px; 
        line-height:48px;
        font-size:18px; 
        color:rgb(255, 255, 255);
        font-weight:500;
        letter-spacing:-0.2pt;  
        font-family: Arial, Helvetica, sans-serif;
        transition: all 0.2s ease;
        cursor: pointer;
    }

    .menu5{
       position: fixed;
       top :50px;
     
        width: 200px;
        left: 80%;
    }
    
    .Header_wrapper{
        top: 0;
        left: 0;
        right: 0;
        z-index: 999;
        max-width: 100%;
        width: 100%;
        height: 64px;
        position:sticky;
        align-items: center;
        background-color:#ba4148;
    
    }
/* 
    .Header_tablet_wrapper{
        top: 64px;
        left: 0;
        right: 0;
        z-index: 99;
        max-width: 100%;
        width: 100%;

        height: 48px;
        position: sticky;
        align-items: center;
        background-color:#363636;
    }*/
     
    .hamburger_menu{
        z-index: 1000;
        position: sticky;
    }
     .ReactModalPortal{
        z-index: 1000;
        position: sticky;
     }
     .slide-pane__header{
        background-color:#ba4148;
     }

    .Header_wrapper_center{
        display: flex;
    }
    .Header_wrapper_center_margin{
        display: flex;
        flex-direction: row;
        margin: 0 auto;
       position: relative;
       justify-content: center;
    }
.Header_second_wrapper_center_margin{
    display: flex;
    flex-direction: row;
   position: relative;
   justify-content: center;
   margin: 0 auto;
}


    .Header_wrapper_center_left{
        display: flex;  
    }
.Header_menu_login{
    position: relative;
    flex-direction: row;
    display: flex;
    margin-left: 10px;
    list-style-type: none
}
.Header_menu_login li{
    list-style-type: none
}
.Tablet_Hamburger_menu {
    position: relative;
    flex-direction: row;
    display: flex;
    /* margin-left: 10px; */
}


.hamburger_Mainmenu_Top{
   text-decoration: none;
 }

 .hamburger_menu li{
    text-decoration: none;
    list-style:none;
    /* padding: 20 0px; */
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .hamburger_menu a{
    text-decoration: none;
    list-style:none;
    color: rgb(59, 59, 59);
}

 
    .Header_menu {
        position: relative;
        flex-direction: row;
        display: flex;
        /* margin-left: 10px; */
    }
    
    .Header_menu a {
        display: block;
        white-space: nowrap;
        text-decoration: none;
    }
    
    .Header_menu li {
        list-style-type: none
    }
    .Header_menu_navbar { padding-left: 20px;     display: flex;
    }
     
    .Header_menu_top_menu a:link, 
    .Header_menu_top_menu a:visited { z-index:-100; display:block; padding:0 22px; line-height:48px; font-size:18px; color:rgb(255, 255, 255); font-weight:500; letter-spacing:-0.5pt;   font-family: Arial, Helvetica, sans-serif;  font-weight:500;   transition: all 0.2s ease;}
    .Header_menu_top_menu a:hover{color:rgb(252, 140, 140); text-decoration:none; }

    .Header_menu_top_right_menu a:link, 
    .Header_menu_top_right_menu a:visited { z-index:-100; display:block; padding:0 28px; line-height:64px; font-size:18px; color:rgb(255, 255, 255); font-weight:500; letter-spacing:0pt;   font-family: Arial, Helvetica, sans-serif;  font-weight:500;   transition: all 0.2s ease;}
    .Header_menu_top_right_menu a:hover{color:rgb(252, 140, 140); text-decoration:none; }


    .Header_menu_top_right_menu span{ cursor: pointer;  background-color: transparent; z-index:-100; display:block; padding:0 28px; line-height:64px; font-size:18px; color:rgb(255, 255, 255); font-weight:500; letter-spacing:0pt;   font-family: Arial, Helvetica, sans-serif;  font-weight:500;   transition: all 0.2s ease;}
    .Header_menu_top_right_menu span:hover{ cursor: pointer;  color:rgb(252, 140, 140); text-decoration:none; }
   
     /* 로그인 했을때 user display */
    .Header_menu_top_right_user_displayName{ cursor: pointer;  background-color: transparent; z-index:-100; display:block; padding:0 10px; line-height:18px; font-size:18px; color:rgb(255, 255, 255); font-weight:500; letter-spacing:0pt;   font-family: Arial, Helvetica, sans-serif;  font-weight:500;   transition: all 0.2s ease;}
    .Header_menu_top_right_user_displayName span:hover{ cursor: pointer;  color:rgb(252, 140, 140); text-decoration:none; }
    
    
    /* 2차 ul */
    .Header_menu div {visibility:hidden; position:absolute; z-index:100; }
    .Header_menu li:hover>div { visibility:visible;    transition: transform 0.6s cubic-bezier(0.83, 0, 0.17, 1);  transform: translate3d(0, 15%, 0);}  
    .Header_menu li li { color:#666; letter-spacing:-0.5pt; padding:3px 15px; text-align:left; font-size:14px; white-space:normal; cursor: pointer;}
    .Header_menu li li :hover{ color:#ec202a;}
    .Header_menu li li:first-child {padding-top:20px;}
    .Header_menu li li:last-child  {padding-bottom:20px;}
    /* 기본 */
    .Header_menu_depth {visibility:hidden; position:absolute; width:150px; }
    .Header_menu_depth ul {margin-top:-40px; box-shadow:0px 0px 10px rgba(102,102,102,0.2); background:rgba(255,255,255,0.95 ); box-sizing:border-box; z-index:100 !important; border-radius:5px;}
    .Header_menu_depth a { color:#666; letter-spacing:-0.5pt; text-align:left; font-size:14px; white-space:normal;}

    /* 기본 */
    .Header_menu_depth_HOME {visibility:hidden; position:absolute; width:150px; }
    .Header_menu_depth_HOME ul {margin-top:-20px; box-shadow:0px 0px 10px rgba(102,102,102,0.2); background:rgba(255,255,255,0.95 ); box-sizing:border-box; z-index:100 !important; border-radius:5px;}
    .Header_menu_depth_HOME a { color:#666; letter-spacing:-0.5pt; text-align:left; font-size:14px; white-space:normal;}
    
    /* genre  */
    .Header_menu_depth_genre {visibility: hidden;  position: absolute; width: 100px;}
    .Header_menu_depth_genre ul {margin-top: -45px;    box-shadow: 0px 0px 10px rgba(102, 102, 102, 0.2); background: rgba(255, 255, 255, 0.95); box-sizing: border-box; z-index: 100 !important;   border-radius: 5px;}
     .Header_menu_depth_genre li a { color:#666; letter-spacing:-0.5pt; text-align:left; font-size:14px; white-space:normal;}
    
    /* MY */
     .Header_menu_depth_my {visibility:hidden; position:absolute; width:150px; }
     .Header_menu_depth_my ul {margin-top:-25px; box-shadow:0px 0px 10px rgba(102,102,102,0.2); background:rgba(255,255,255,0.95 ); box-sizing:border-box; z-index:100 !important; border-radius:5px;}
     .Header_menu_depth_my li a { color:#666; letter-spacing:-0.5pt; text-align:left; font-size:14px; white-space:normal;}

      
    .Header_menu_search_wrap{display: flex;    background-color: transparent;   transition: all 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
        height: 64px;
        width: 30%; 
      align-items: center;
      position: relative;
      margin-left: 20px;
      flex-direction: row;
   
  }
   
  .Header_menu_search{
      background-color: rgba(255, 255, 255, 0.007);
      width: 400px; 
      height: 40px;
      font-size: 14px;
      font-weight: 500;
      padding: 0 0px 0 15px;
      transition: all 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
      border: 2.5px solid rgb(255, 255, 255);
      border-radius: 20px;
      font-size:12pt;
       color:rgb(255, 255, 255);

      
  }
 
     #search:focus {
      outline: none;
      /* border: 2.5px solid rgb(255, 255, 255); */
    }
    #search::placeholder {
      color: rgb(255, 255, 255);
      font-weight: 500;
      font-size: 14px;
    }
    
.search__barSearchIcon{ 
     color: rgb(255, 255, 255) !important;
     position: absolute;
     top: 20px;
     right: 20px;
     background-color: transparent;
     background-size: cover;
     background-position: center;
     margin: 0;
     cursor: pointer;
}

.Header_menu_center_right{
  display: flex;   background-color: transparent; 
        height: 64px;
        /* width: 300px;  */
      align-items: center;
      position: relative;
      margin-left: 40px;
      flex-direction: row;
}


.languge_svgIcon{
  position: absolute;
     top: 20px;
     left: -10px;
     margin: 0;
     color: rgb(255, 255, 255) !important; 
     transform: scale(1.1) !important;
     /* padding: 2px; */
}


.menu_svgIcon{
    /* position: relative; */
    /* align-items: center;
    margin-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  */
       color: rgb(255, 255, 255) !important; 
       transform: scale(1.4) !important;
       /* padding: 2px; */
  }



.login_svgIcon{
    /* position: absolute; */
       top: 32px;
       /* left: -10px; */
       margin: 0;
       color: rgb(255, 255, 255) !important; 
       transform: scale(1.1) !important;
       /* padding: 2px; */

  
  }

.navbar__avatar{
  /* position: absolute; */
  top: 0px;
  /* left: 5px; */
  margin: 0;
  color: rgb(255, 255, 255) !important;
  transform: scale(0.8) !important; 
}

.Header_wrapper_menu{
    /* justify-content: center; */
    /* margin: auto; */
    /* display: block; */
    /* width: 50%; */
    /* align-items: center; */
}

}




@media screen and (max-width:915px) {


    .menu_svgIcon{
        position: relative;
        align-items: center;
        margin-top: 2px;
        justify-content: center;
        align-items: center;
        /* padding : 10px; */
           color: rgb(255, 255, 255) !important; 
           transform: scale(1.4) !important;

      }


    .Header_wrapper{
        top: 0;
        left: 0;
        right: 0;
        z-index: 999;
        width:100%;
        height: 64px;
        position: sticky;
        align-items: center;
        background-color:#ba4148;
    
    }
    /* .Header_tablet_wrapper{
        top: 64px;
        left: 0;
        right: 0;
        z-index: 99;
        width:100%;
        height: 48px;
        position: sticky;
        align-items: center;
        background-color:#363636;
    
    } */

    .Header_wrapper_center{
        display: flex;
    }
    .Header_wrapper_center_margin{
        display: flex;
        flex-direction: row;
        margin: auto;
        position: relative;
        justify-content: center;
    
    }
    .Header_second_wrapper_center_margin{
        display: flex;
        flex-direction: row;
        position: relative;
        justify-content: center;
        margin: 0 auto;
    }


    .Header_wrapper_center_left{
        display: flex;  
    }
    .Header_menu_login{
        position: relative;
        flex-direction: row;
        display: flex;
        margin-left: 10px;
    }
    .Header_menu_login li{
        list-style-type: none
    }


    .Header_menu {
        position: relative;
        flex-direction: row;
        display: flex;
        /* margin-left: 10px; */
    }
    
    .Header_menu a {
        display: block;
        white-space: nowrap;
        text-decoration: none;
    }
    
    .Header_menu li {
        list-style-type: none
    }
    .Header_menu_navbar { 
        padding-left: 0px;    
         display: flex; 
         justify-content: center;
         align-items: center;
         height: 64px;
    }

    .Header_menu_top_menu_home {visibility:visible; display:flex; flex-direction: column; }
    .Header_menu_top_menu_home a:link, 
    .Header_menu_top_menu_home a:visited {  z-index:-100; display:block; padding:0 12px; line-height:48px; font-size:12px; color:rgb(248, 248, 248); font-weight:500; letter-spacing:-0.2pt;   font-family:Arial, Helvetica, sans-serif;  font-weight:500;   transition: all 0.2s ease;}
    .Header_menu_top_menu_home a:hover{color:rgb(252, 140, 140); text-decoration:none; }


    /* <div className="Header_menu_home">
    <TranslateIcon className="Header_homeIcon" />
    <span className="Header_menu_home_text">HOME</span>
  </div> */


    .Header_homeIcon{
        position: absolute;
        top: 0px;
        margin: 0;
           color: rgb(255, 255, 255) !important; 
           transform: scale(0.7) !important;
      }


    .Header_menu_top_menu a:link, 
    .Header_menu_top_menu a:visited { flex-direction: column;  z-index:-100; display:block; padding:0 12px; line-height:48px; font-size:12px; color:rgb(248, 248, 248); font-weight:500; letter-spacing:-0.2pt;   font-family:Arial, Helvetica, sans-serif;  font-weight:500;   transition: all 0.2s ease;}
    .Header_menu_top_menu a:hover{color:rgb(252, 140, 140); text-decoration:none; }

    .Header_menu_top_right_menu a:link, 
    .Header_menu_top_right_menu a:visited { z-index:-100; display:block; padding:0 8px;  padding-left: 28px;  line-height:48px; font-size:12px; color:rgb(248, 248, 248); font-weight:500; letter-spacing:0pt;   font-family: Arial, Helvetica, sans-serif;  font-weight:500;   transition: all 0.2s ease;}
    .Header_menu_top_right_menu a:hover{color:rgb(252, 140, 140); text-decoration:none; }


    .Header_menu_top_right_menu span{ cursor: pointer;  background-color: transparent; z-index:-100; display:block; padding:0 20px; line-height:48px; font-size:12px; color:rgb(248, 248, 248);; font-weight:500; letter-spacing:0pt;   font-family: Arial, Helvetica, sans-serif;  font-weight:500;   transition: all 0.2s ease;}
    .Header_menu_top_right_menu span:hover{ cursor: pointer;  color:rgb(252, 140, 140); text-decoration:none; }
   
    .Header_menu_top_right_user_displayName span:hover{ cursor: pointer;  color:rgb(252, 140, 140); text-decoration:none; }
         /* 로그인 했을때 user display */
    .Header_menu_top_right_user_displayName span{width: 90px; cursor: pointer;  background-color: transparent; z-index:-100; display:block; padding:0 6px; line-height:15px; font-size:12px; color:rgb(248, 248, 248);; font-weight:500; letter-spacing:0pt;   font-family: Arial, Helvetica, sans-serif;  font-weight:500;   transition: all 0.2s ease;}

    
    /* 2차 ul */
    /* .Header_menu div {visibility:hidden; position:absolute; z-index:100; } */
    .Header_menu li:hover>div { visibility:visible;    transition: transform 0.6s cubic-bezier(0.83, 0, 0.17, 1);  transform: translate3d(0, 15%, 0);}  
    .Header_menu li li { color:#666; letter-spacing:-0.5pt; padding:3px 15px; text-align:left; font-size:14px; white-space:normal; cursor: pointer;}
    .Header_menu li li :hover{ color:#ec202a;}
    .Header_menu li li:first-child {padding-top:20px;}
    .Header_menu li li:last-child  {padding-bottom:20px;}
    /* 기본 */
    .Header_menu_depth {visibility:hidden; position:absolute; width:150px; }
    .Header_menu_depth ul {margin-top:-40px; box-shadow:0px 0px 10px rgba(102,102,102,0.2); background:rgba(255,255,255,0.95 ); box-sizing:border-box; z-index:100 !important; border-radius:5px;}
    .Header_menu_depth a { color:#666; letter-spacing:-0.5pt; text-align:left; font-size:14px; white-space:normal;}

    /* 기본 */
    .Header_menu_depth_HOME {visibility:hidden; position:absolute; width:150px; }
    .Header_menu_depth_HOME ul {margin-top:-20px; box-shadow:0px 0px 10px rgba(102,102,102,0.2); background:rgba(255,255,255,0.95 ); box-sizing:border-box; z-index:100 !important; border-radius:5px;}
    .Header_menu_depth_HOME a { color:#666; letter-spacing:-0.5pt; text-align:left; font-size:14px; white-space:normal;}
    
    /* genre  */
    .Header_menu_depth_genre {visibility: hidden;  position: absolute; width: 100px;}
    .Header_menu_depth_genre ul {margin-top: -45px;    box-shadow: 0px 0px 10px rgba(102, 102, 102, 0.2); background: rgba(255, 255, 255, 0.95); box-sizing: border-box; z-index: 100 !important;   border-radius: 5px;}
     .Header_menu_depth_genre li a { color:#666; letter-spacing:-0.5pt; text-align:left; font-size:14px; white-space:normal;}
    
    /* MY */
     .Header_menu_depth_my {visibility:hidden; position:absolute; width:150px; }
     .Header_menu_depth_my ul {margin-top:-25px; box-shadow:0px 0px 10px rgba(102,102,102,0.2); background:rgba(255,255,255,0.95 ); box-sizing:border-box; z-index:100 !important; border-radius:5px;}
     .Header_menu_depth_my li a { color:#666; letter-spacing:-0.5pt; text-align:left; font-size:14px; white-space:normal;}

      
    .Header_menu_search_wrap{
      display: flex;    
      background-color: transparent;   
      transition: all 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
      height: 64px;
      align-items: center;
      position: relative;
      flex-direction: row;
      padding: 10px;

  }
   
  .Header_menu_search{
      background-color: rgba(255, 255, 255, 0.007);
      width: 100%; 
      height: 40px;
      font-size: 14px;
      font-weight: 500;
      padding: 0 0px 0 15px;
      transition: all 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
      border: 2.5px solid rgb(255, 255, 255);
      border-radius: 20px;
      font-size:12pt;
       color:rgb(255, 255, 255);

      
  }
 
     #search:focus {
      outline: none;
      /* border: 2.5px solid rgb(255, 255, 255); */
    }
    #search::placeholder {
      color: rgb(255, 255, 255);
      font-weight: 500;
      font-size: 14px;
    }
    
.search__barSearchIcon{ 
     color: rgb(255, 255, 255) !important;
     position: absolute;
     top: 20px;
     right: 20px;
     background-color: transparent;
     background-size: cover;
     background-position: center;
     margin: 0;
     cursor: pointer;
     transform: scale(1.1) !important;
}

.Header_menu_center_right{
  display: flex;  
   background-color: transparent; 
        /* width: 50px;  */
      align-items: center;
      position: relative;
      /* margin-left: 16px; */
      flex-direction: row;
      height: 64px;
}


.languge_svgIcon{
  /* position: absolute; */
     /* top: 12px; */
     left: 0px;
     margin: 0;
     color: rgb(255, 255, 255) !important; 
     transform: scale(1.2) !important;
     margin-top: 4px;


}
.login_svgIcon{
       align-items: center;
       justify-content: center;
       margin: 0;
       color: rgb(255, 255, 255) !important; 
       transform: scale(0.9) !important;
      
  }

.navbar__avatar{
  /* position: absolute; */
  top: 0px;
  /* left: 5px; */
  margin: 0;
  color: rgb(255, 255, 255) !important;
  transform: scale(0.8) !important; 
}

       

}