@media only screen and (min-width: 1367px) {
	.Homeslider {
	width: 100%;
	background-color: #ddd;
	z-index: 0;
}




.Homeslider_text {
	left: 51.5%;
	top: 59%;
	z-index: 300;
	position: absolute;
	overflow: hidden;
	word-break: break-all;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-style: italic;
	font-family: "Poppins", sans-serif;
	text-transform: uppercase;
	/* color: #222222; */
	font-size: 42px;
	line-height: 1em;
	font-weight: 600;
	text-align: right;
	padding-right: 10px;
	/* margin-right: 60px; */
	width: 32%;
	display: block;

}

.Homeslider_text2 {
	width: 32%;
	text-align: right;
	left: 51.3%;
	top: 76%;
	z-index: 300;
	position: absolute;
	/* color: #424242; */
	font-size: 1.2em;
	line-height: 1em;
	overflow: hidden;
	word-break: break-all;
	text-overflow: ellipsis;
	white-space: nowrap;

	font-size: 30px;
	font-family: "Poppins", sans-serif;
	text-transform: uppercase;
	box-sizing: inherit;
	display: block;
	opacity: .8;


	font-weight: 600;
}

.Homeslider__image24 {
	width: 70%;
	height: 350px;
	object-fit: cover;
	z-index: 10 !important;
	;
	opacity: 0.3 !important;
	margin: auto;
	filter: blur(5px);
	-webkit-filter: blur(5px);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

}

.Homeslider-image_opacity1 {
	min-width: 350px;
	height: 350px;
	border-radius: 10px;
	transition: 0.3s ease;
	user-select: none;
	position: absolute;
	left: 15%;
}

.Homeslider__image25 {

	color: black !important;
	box-shadow: 0 3px 10px 5px rgba(0, 0, 0, 0.1);
	width: 350px;
	height: 350px;
	object-fit: cover;
	z-index: 1 !important;
	opacity: 1 !important;
	position: absolute;
}


.slick-slide.slick-center img {
	opacity: 1 !important;
	z-index: 1 !important;
}

.slick-slide {
	position: relative;
	z-index: 2;
}

}

@media screen and (max-width:1366px) and (min-width:916px) {

	.Homeslider {
		width: 100%;
		background-color: #ddd;
		z-index: 0;
		/* margin-top: 112px; */
	}

	.Homeslider_text {
		left: 56%;
		top: 59%;
		z-index: 300;
		position: absolute;
		overflow: hidden;
		word-break: break-all;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-style: italic;
		font-family: "Poppins", sans-serif;
		text-transform: uppercase;
		/* color: #222222; */
		font-size: 36px;
		line-height: 1em;
		font-weight: 600;
		text-align: right;
		padding-right: 10px;
		/* margin-right: 60px; */
		width: 43%;
		display: block;
	
	}
	
	.Homeslider_text2 {
		width: 43%;;
		text-align: right;
		left: 56.0%;
		top: 76%;
		z-index: 300;
		position: absolute;
		/* color: #424242; */
		font-size: 1.2em;
		line-height: 1em;
		overflow: hidden;
		word-break: break-all;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-size: 30px;
		font-family: "Poppins", sans-serif;
		text-transform: uppercase;
		box-sizing: inherit;
		display: block;
		opacity: .8;
		font-weight: 600;
		padding-right: 10px;
	}
	
	.Homeslider__image24 {
		width: 100%;
		height: 300px;
		object-fit: cover;
		z-index: 10 !important;
		;
		opacity: 0.3 !important;
		margin: auto;
		filter: blur(5px);
		-webkit-filter: blur(5px);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	
	}
	
	.Homeslider-image_opacity1 {
		min-width: 350px;
		height: 350px;
		border-radius: 10px;
		transition: 0.3s ease;
		user-select: none;
		position: absolute;
		left: 00px;
	}
	
	.Homeslider__image25 {
	
		color: black !important;
		box-shadow: 0 3px 10px 5px rgba(0, 0, 0, 0.1);
		width: 300px;
		height: 300px;
		object-fit: cover;
		z-index: 1 !important;
		opacity: 1 !important;
		position: absolute;
	}
	
	
	.slick-slide.slick-center img {
		opacity: 1 !important;
		z-index: 1 !important;
	}
	
	.slick-slide {
		position: relative;
		z-index: 2;
	}
	

}

@media screen and (max-width:915px) {

	.Homeslider {
		width: 100%;
		background-color: #ddd;
		z-index: 0;
		margin:  auto;
		/* margin-top: 112px; */

	}

	.Homeslider_text {
		left: 55%;
		top: 57%;
		z-index: 300;
		position: absolute;
		overflow: hidden;
		word-break: break-all;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-style: italic;
		font-family: "Poppins", sans-serif;
		text-transform: uppercase;
		font-size: 28px;
		line-height: 1em;
		font-weight: 600;
		text-align: right;
		padding-right: 10px;
		width: 43%;
		display: block;
	
	}
	
	.Homeslider_text2 {
		width: 40%;
		text-align: right;
		left: 58.0%;
		top: 76%;
		z-index: 300;
		position: absolute;
		/* color: #424242; */
		font-size: 1.2em;
		line-height: 1em;
		overflow: hidden;
		word-break: break-all;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-size: 20px;
		font-family: "Poppins", sans-serif;
		text-transform: uppercase;
		box-sizing: inherit;
		display: block;
		opacity: .8;
		font-weight: 600;
		padding-right: 10px;
	}
	
	.Homeslider__image24 {
		width: 100%;
		height: 200px;
		object-fit: cover;
		z-index: 10 !important;

		opacity: 0.3 !important;
		margin: auto;
		filter: blur(5px);
		-webkit-filter: blur(5px);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	
	}
	
	.Homeslider-image_opacity1 {
		min-width: 200px;
		height: 200px;
		border-radius: 10px;
		transition: 0.3s ease;
		user-select: none;
		position: absolute;
		left: 00px;
	}
	
	.Homeslider__image25 {
	
		color: black !important;
		box-shadow: 0 3px 10px 5px rgba(0, 0, 0, 0.1);
		width: 100%;
		height: 100%;
	
		object-fit: cover;
		z-index: 1 !important;
		opacity: 1 !important;
		position: absolute;
	}
	
	
	.slick-slide.slick-center img {
		opacity: 1 !important;
		z-index: 1 !important;
	}
	
	.slick-slide {
		position: relative;
		z-index: 2;
	}
	

}
