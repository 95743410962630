@media only screen and (min-width: 1024px){

 

    .MyHome_main_card {
        position: relative;
        display: flex;
        /* background: linear-gradient( rgba(247, 116, 116, 0.253) 36%, #fff 15% ); */
        border-radius: 15px;
        box-shadow: 2px 8px 12px #1414140a, 0 1px 3px #14141405, 0 0 0 1px #00000005;
        text-align: center;
        /* padding-top: 70px; */
        transition: all 0.5s;
        width: 98%;
        height: 220px;
        margin-left: 1%;
        margin-right: 1%;
        flex-direction: row;
    }

    .card-img-wrapper {
        position: relative;
        height: 120px;
        width: 120px;
        margin:auto;
        margin-left: 30px;
        /* margin-bottom: 20px; */
        border-radius: 100%;
    }
    
    .upload_public_card_bottom{
        flex-direction: row;
        position: relative;
        display: flex;
        /* margin: 20px auto; */
    }
    
    
    .upload_public_card_bottom_paypal{
        position: relative;
        display: flex;
        /* background: linear-gradient( rgba(247, 116, 116, 0.253) 15%, #fff 15% ); */
        background: #fff;
        /* flex-direction: row; */
        border-radius: 15px;
        box-shadow: 2px 8px 12px #1414140a, 0 1px 3px #14141405, 0 0 0 1px #00000005;
        text-align: center;
        transition: all 0.5s;
        height: 580px;
        margin-left: 1%;
        margin-right: 1%;
        width: 98%;
        flex-direction: column;
        margin-top: 24px;
    }

    .MyHome_bottom_card{
        flex-direction: row;
        position: relative;
        display: flex;
        margin: 20px auto;
        width: 98%;
        margin-left: 1%;
        margin-right: 1%;
    }



    .MyHome_bottom_paypal_card{
        position: relative;
        display: flex;
        /* background: linear-gradient( rgba(247, 116, 116, 0.253) 15%, #fff 15% ); */
        border-radius: 15px;
        box-shadow: 2px 8px 12px #1414140a, 0 1px 3px #14141405, 0 0 0 1px #00000005;
        text-align: center;
        transition: all 0.5s;
        height: 580px;
        width: 35%;
        
    }


    .MyHome_bottom_button_enrollment {
        margin-left: auto;
        /* flex-direction: row; */
      }

      .MyHome_bottom_discription_card{
        margin-left: 20px;
        position: relative;
        display: flex;
        border-radius: 15px;
        box-shadow: 2px 8px 12px #1414140a, 0 1px 3px #14141405, 0 0 0 1px #00000005;
        text-align: center;
        transition: all 0.5s;
        height: 580px;
        width: 65%;
    }

    .MyHome_bottom_discription_card_content{
        padding-left: 30px;
        padding-right: 30px;
        margin-top: 14px;
        width: 100%;
    }

    .MyHome_bottom_card_amount_content{
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 14px;
        width: 100%;
    }

    .MyHome_bottom_discription_card_entiretext{
        width: 100%; 
        height: 150px;
        border: 1px solid #EAEAEA;
        border-radius: 15px;
        resize: none;
        font-size: 18px;
        margin: auto;
        background-color: transparent;
        padding-left: 10px;
        margin-top: 8px;
        /* margin-top: 12px; */
    }

 
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

    .MyHome_bottom_discription_card_amount{
        margin-left: 20px;
        position: relative;
        display: flex;
        /* background: linear-gradient( rgba(247, 116, 116, 0.253) 15%, #fff 15% ); */
        border-radius: 15px;
        box-shadow: 2px 8px 12px #1414140a, 0 1px 3px #14141405, 0 0 0 1px #00000005;
        text-align: center;
        transition: all 0.5s;
        height: 540px;
        width: 20%;
    }

.MyHome_PHOTO_EDIT_BUTTON{
    position: relative;
    transform: scale(1.1);
    align-items: left;
    /* display:inline-block; */
    text-align: left;
    justify-content: left;
    display: block;
    padding-left: 18px;
    margin-top: 12px;
    /* left: 870px; */
    /* top: 200px; */
}


.MyHome_table{
    width: 50%;
    height: 40px;
    border-collapse: collapse;
    text-align: center;
    /* margin: auto;    */
    table-layout: fixed;
    cursor:default;
    outline:none;
    margin-top: 20px;
    /* margin-left: 50px; */
    /* margin-bottom: 100px; */
   
}
.MyHome_table_right{
    width: 50%;
    height: 40px;
    border-collapse: collapse;
    text-align: center;
    /* margin: auto;    */
    table-layout: fixed;
    cursor:default;
    outline:none;
    margin-left: 50px;
    margin-top: 20px;

    /* margin-bottom: 100px; */
   
}
.MyHome_tableHead_download{
    text-align: right;
}
.MyHome_tableHead_favorite{
    text-align: right;

}
.MyHome_tbody_table{
    border-bottom: 1px solid #97979754;
    text-align: center;
    width: 750px;
    height: 60px;
    outline:none;
}
.MyHome_table_thead{
    border-bottom: 1px solid #97979754;
    height: 40px;
}
.MyHome_table_thead_tr{
    font-size: 14px;
    
}
.MyHome_tbody_table :hover{background-color:rgb(241, 241, 241);  }
.MyHome_tbody_tr{width: 750px; cursor: pointer; }
.MyHome_tbody_index{ width: 80px; font-size: 18px; font-family:Arial, Helvetica, sans-serif; font-weight: 400; color:rgb(138, 138, 138); font-style: normal;}
.MyHome_tbody_image{
    cursor: pointer;
    min-width: 60px;
    max-width:60px;
    min-height: 60px;
    max-height: 60px;
    object-fit: cover;
    align-items: center;
    transition: 0.3s ease;
    position: absolute;
    padding: 10px;
    margin-top: -20px;
    /* padding-bottom: ; */
    /* top: -100px; */
    /* margin-bottom: 30px; */
}
.MyHome_tbody_artist{  
    cursor: pointer; 
    position:relative;   
    text-align: left; 
    /* width: 360px;  */
    /* height: 90px; */
    padding-left: 10px;   
	overflow:hidden;
    word-break:break-all;
    text-overflow:ellipsis;
    white-space : normal; /*기본값*/
    text-overflow: clip; /*기본값*/
    /* text-align: center; */
        }  
        
        
.MyHome_songName{
    flex-direction: column;
    display: inline-block;
    text-align: center;
    justify-content: center;
    line-height: 10px;
    margin-left: 70px;
    font-size: 14px;
    /* height: 40px; */
    /* margin-bottom: 50px; */
}
.MyHome_artistname{
    font-size: 11px;
    color: #6e6e6e;
}

.MyHome_tbody_genres_edit{  opacity: 0.7;   width: 185px;  padding-left: 10px;  padding-right: 10px;  font-size: 14px; text-align: center; } 
.MyHome_tbody_price{
    text-align: center;
    opacity: 0.7; 
    width: 180px;

}
.MyHome_tbody_upgrade{   color: rgb(138, 138, 138); width: 75px;   padding-left: 0px; padding-right: 30px; text-align: right; }
.MyHome_tbody_favorite{   color: rgb(138, 138, 138); width: 90px; padding-right: 30px; text-align: right;}
.MyHome_tbody_damgi{padding-right: 20px; text-align: right; color: rgb(138, 138, 138);}
.MyHome_tbody_date{opacity: 0.7; font-size: 14px;  }

.MyHome_tbody_download_Icon{  width: 10px;  margin-top: -21px;  left:560px;  position: absolute; }
.MyHome_tbody_favorite_Icon{  width: 10px;    left:640px;  position: absolute; margin-top: -20px;;}

.MyHome_tbody_right_download_Icon{  width: 10px;  margin-top: -21px;  left:1360px;  position: absolute; }
.MyHome_tbody_right_favorite_Icon{  width: 10px;    left:1440px;  position: absolute; margin-top: -20px;;}

.MyHome_tbody_Look_Icon{  width: 10px;    left:15px;  position: absolute; margin-top: -20px;;}
.MyHome__Icon{
    cursor:none;
    transform: scale(0.7);
}
  








}




   

 

   
  
 