@media only screen and (min-width: 1367px) {



	.Genresslider {
		height: 250px;
		width: 80%;
		margin: auto;

	}


	.Genresslider_text {
		height: 60px;
		width: 50%;
		text-align: right;
		left: 46%;
		top: 48%;
		z-index: 300;
		position: absolute;
		font-size: 40px;
		line-height: 32px;
		overflow: hidden;
		word-break: break-all;
		text-overflow: ellipsis;
		white-space: nowrap;
		animation: titleAnimation 8s ease-in-out infinite;
		padding-top: 15px;
	}

	.Genresslider_text2 {
		width: 50%;
		height: 45px;
		text-align: right;
		left: 46%;
		top: 72%;
		padding-top: 10px;
		/* margin-left: 1200px; */
		z-index: 300;
		position: absolute;
		font-size: 28px;
		line-height: 21px;
		overflow: hidden;
		word-break: break-all;
		text-overflow: ellipsis;
		white-space: nowrap;
		animation: titleAnimation 8s ease-in-out infinite;

	}

	@keyframes titleAnimation {
		0% {
			transform: translateY(50px);
			opacity: 0;
			-webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
			clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
		}

		20% {
			transform: translateY(0);
			opacity: 1;
			-webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
			clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
		}

		80% {
			transform: translateY(0);
			opacity: 1;
			-webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
			clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
		}

		100% {
			transform: translateY(-50px);
			opacity: 0;
			-webkit-clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
			clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);

		}
	}

	.Genresslider__image24 {
		object-fit: cover;
		opacity: 0.9 !important;
		width: 100%;
		height: 250px;
	}

	/* #animate-area	{ 
		width: 1500px;
		height: 250px;
	
	} */

	.Genresslider__image25 {
		width: 250px;
		height: 250px;
		object-fit: cover;
		z-index: 1 !important;
		position: relative;
		margin-left: 30px;


	}

	.Genresslider-image_opacity1 {
		position: absolute;
	}



	.slick-slide.slick-center img {
		opacity: 1 !important;
		z-index: 1 !important;
	}

	.slick-slide {
		position: relative;
		z-index: 2;
	}


}


@media screen and (max-width:1366px) and (min-width:916px) {
	
	.Genresslider {
		height: 250px;
		width: 90%;
		margin: auto;
		/* margin-top: 140px; */
	}


	.Genresslider_text {
		height: 60px;
		width: 50%;
		text-align: right;
		left: 46%;
		top: 52%;
		z-index: 300;
		position: absolute;
		font-size: 36px;
		line-height: 32px;
		overflow: hidden;
		word-break: break-all;
		text-overflow: ellipsis;
		white-space: nowrap;
		animation: titleAnimation 8s ease-in-out infinite;
		padding-top: 15px;
	}

	.Genresslider_text2 {
		width: 50%;
		height: 45px;
		text-align: right;
		left: 46%;
		top: 73%;
		padding-top: 10px;
		z-index: 300;
		position: absolute;
		font-size: 24px;
		line-height: 21px;
		overflow: hidden;
		word-break: break-all;
		text-overflow: ellipsis;
		white-space: nowrap;
		animation: titleAnimation 8s ease-in-out infinite;

	}

	@keyframes titleAnimation {
		0% {
			transform: translateY(50px);
			opacity: 0;
			-webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
			clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
		}

		20% {
			transform: translateY(0);
			opacity: 1;
			-webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
			clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
		}

		80% {
			transform: translateY(0);
			opacity: 1;
			-webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
			clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
		}

		100% {
			transform: translateY(-50px);
			opacity: 0;
			-webkit-clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
			clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);

		}
	}

	.Genresslider__image24 {
		object-fit: cover;
		opacity: 0.9 !important;
		width: 100%;
		height: 250px;
	}

	/* #animate-area	{ 
		width: 1500px;
		height: 250px;
	
	} */

	.Genresslider__image25 {
		width: 250px;
		height: 250px;
		object-fit: cover;
		z-index: 1 !important;
		position: relative;
		margin-left: 30px;


	}
	 .Genresslider-image_opacity1 {
		position: absolute;
	}
	.slick-slide.slick-center img {
		opacity: 1 !important;
		z-index: 1 !important;
	} 
	 .slick-slide {
		position: relative;
		z-index: 2;
	} 
	}
	

@media screen and (max-width:915px) {
.Genresslider {
		height: 200px;
		width: 90%;
		margin: auto;
		/* margin-top: 40px; */
	}


	.Genresslider_text {
		height: 60px;
		width: 50%;
		text-align: right;
		left: 46%;
		top: 45%;
		z-index: 300;
		position: absolute;
		font-size: 30px;
		line-height: 32px;
		overflow: hidden;
		word-break: break-all;
		text-overflow: ellipsis;
		white-space: nowrap;
		animation: titleAnimation 8s ease-in-out infinite;
		padding-top: 15px;
	}

	.Genresslider_text2 {
		width: 50%;
		height: 45px;
		text-align: right;
		left: 46%;
		top: 69%;
		padding-top: 10px;
		z-index: 300;
		position: absolute;
		font-size: 24px;
		line-height: 21px;
		overflow: hidden;
		word-break: break-all;
		text-overflow: ellipsis;
		white-space: nowrap;
		animation: titleAnimation 8s ease-in-out infinite;

	}

	@keyframes titleAnimation {
		0% {
			transform: translateY(50px);
			opacity: 0;
			-webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
			clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
		}

		20% {
			transform: translateY(0);
			opacity: 1;
			-webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
			clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
		}

		80% {
			transform: translateY(0);
			opacity: 1;
			-webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
			clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
		}

		100% {
			transform: translateY(-50px);
			opacity: 0;
			-webkit-clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
			clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);

		}
	}

	.Genresslider__image24 {
		object-fit: cover;
		opacity: 0.9 !important;
		height: 200px;
		width: 100%;
	}

	/* #animate-area	{ 
		width: 1500px;
		height: 250px;
	
	} */

	.Genresslider__image25 {
		width: 200px;
		height: 200px;
		object-fit: cover;
		z-index: 1 !important;
		position: relative;
		margin-left: 30px;

	}
	.Genresslider-image_opacity1 {
		position: absolute;
	}
	 .slick-slide.slick-center img {
		opacity: 1 !important;
		z-index: 1 !important;
	}
	.slick-slide {
		position: relative;
		z-index: 2;
	} 
	}