
.Payment_checkout__left_table{
    width: 100%;
    height: auto;
    border-collapse: collapse;
    text-align: center;
    margin: auto;
    table-layout: fixed;
    margin-top: 20px;
}


.Payment_checkout__left_thead{
   
    border-bottom: 1px solid #97979754;
    border-top: 1px solid #97979754;
    padding-top: 20px;
    padding-bottom: 20px;

}

.Payment_checkout__left_content_allcheckbox{
    height: 60px;
    font-size: 18px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    display: flex;
    }
.Payment_checkout__left_content_product{
    height: 60px;
    font-size: 18px;
    font-weight: 600;
}
.Payment_checkout__left_content_price{
    height: 60px;
    font-size: 18px;
    font-weight: 600;
}

.Payment_checkout__left_content_tbody{
    border-bottom: 1px solid #97979754;
    text-align: center;
    width: 100%;

}


.Payment_checkout__left_content_tbody_checkbox{
    width: 120px;
    justify-content: center;
    /* display: inline-block; */
    align-items: center;
    text-align: center;
    /* margin: auto; */
}

.Payment_checkout__left_content_tbody_image{
    cursor: pointer;
    min-width: 75px;
    max-width:75px;
    min-height: 75px;
    max-height: 75px;
    object-fit: cover;
    align-items: center;
    transition: 0.3s ease;
    position: relative;
    padding: 10px;
}
.Payment_checkout__left_content_song_artist{ display:flex; flex-direction: row; cursor: pointer;  position:relative;    text-overflow:ellipsis;   word-break:break-all;  overflow:hidden;  text-align: left;   line-height: 24px; }   

.Payment_checkout__left_content_tbody_songName{
    flex-direction: column;
    display: flex;
    text-align: left;
    justify-content: center;
    line-height: 21px;
    font-size: 15px;
    color: #666666;
    overflow:hidden;
    white-space : nowrap;
    text-overflow: ellipsis;
    margin-left: 10px;
}
.Payment_checkout__left_content_song_artist{  
    cursor: pointer; 
    position:relative;   
    text-align: left; 
    height: 80px;
 
    line-height: 20px;
	overflow:hidden;
    word-break:break-all;
    text-overflow:ellipsis;
    white-space : normal; /*기본값*/
    text-overflow: clip; /*기본값*/
        }   

.Payment_checkout__left_content_tbody_artistname{  position: relative;  font-size: 10px;}
.Payment_checkout__left_content_tbody_price{
    text-align: center;
    color: rgb(26, 26, 26);
    width: 150px;
    

}
.Payment_checkout__left_content_tbody_closeIcon{
    width: 120px;
}

.Payment_checkout__left_content_tbody_total{ 
    border-bottom: 1px solid #97979754;
    height: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
}


.Payment_checkout__right_content_card {
    position: relative;
    display: inline-block;
    background:  #fff ;
    border-radius: 15px;
    box-shadow:  0 4px 6px rgba(129, 129, 129, 0.16);
    text-align: center;
    padding-top: 10px;
    transition: all 0.5s;
    height: 400px;
    width: 80%;
  
}

.Payment_checkout__right_content_card_total{
    width: 80%;
    margin: 10px auto;
    font-size: 30px;
    text-align: left;
    border-bottom: 1px solid #97979754;
 
}
.Payment_checkout__right_content_card_total_price{
    width: 80%;
    margin: auto;
    font-size: 30px;
    text-align: left;
    border-bottom: 1px solid #97979754;
    justify-content: space-between;
    display: flex;
    /* margin-top: 30px; */
    height: 80px;

    margin-top: 40px;
}


.ArrowForwardIos_icon{
    color : rgb(211, 211, 211);
    margin-top: 15px;
    transform: scale(0.8);
}
  





