
.paymentHistory_main_content{
    /* background-color: rgb(255, 255, 255); */
    width: 100%;
    /* height: 250px; */
    position: relative;
    display: flex;
    flex-direction: column;
    /* margin: auto; */
    /* margin: 80px auto; */
}

.paymentHistory_main_card {
    position: relative;
    display: flex;
    /* background: linear-gradient( rgba(255, 162, 108, 0.253) 15%, #fff 15% ); */
    /* background: #fff 15%; */
    height: 880px;
    border-radius: 15px;
    box-shadow: 2px 8px 12px #1414140a, 0 1px 3px #14141405, 0 0 0 1px #00000005;
    text-align: center;
    /* padding-top: 8%; */
    transition: all 0.5s;
    width: 84%;
    margin-left: 1%;
    margin-right: 1%;
}

.paymentHistory_main_Circle{
    width: 140px;
    height: 140px;
    border-radius: 140px;
    background-color: rgb(223, 223, 223);
     display: flex;
     margin: 10px auto;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    font-size: 33px;
    color: rgb(39, 39, 39);
    font-weight: 600;
}





.paymentHistory_table{
    width: 90%;
    height: auto;
    border-collapse: collapse;
    text-align: center;
    /* margin: auto; */
    margin-bottom: auto;  
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    justify-content: center;
    table-layout: fixed;
    cursor:default;
    outline:none;
}

.paymentHistory_table_thead_tr{
    padding-top: 20px;
    padding-bottom: 20px;
}


.paymentHistory_table_thead{
    border-bottom: 1px solid #8b8b8b98;
    border-top: 1px solid #8b8b8b98;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 50px;
}

.paymentHistory_tableHead_index{    width: 80px;}
.paymentHistory_tableHead_merchant_uid{   width: 70px; padding-left: 0px; text-align: center; }
.paymentHistory_tableHead_amount{   width: 80px; padding-left:0px; text-align: center;}
.paymentHistory_tableHead_merchant_name{   width: 80px; padding-left:0px; text-align: center;}
.paymentHistory_tableHead_pg{   width: 80px; padding-left:0px; text-align: center;}
.paymentHistory_tableHead_purchaseDate{   width: 80px; padding-left:0px; text-align: center;}
.paymentHistory_tableHead_question{   width: 80px; padding-left:0px; text-align: center;}




/* ///////////////////////////////////T-BODY///////////////////////////////////////// */

.paymentHistory_tbody_table{
    border-bottom: 1px solid #97979754;
    text-align: center;
    width: 100%;
    height: 60px;
    outline:none;
}


.paymentHistory_tbody_index{ width: 80px; font-size: 18px; font-family:Arial, Helvetica, sans-serif; font-weight: 400; color:rgb(138, 138, 138); font-style: normal;}

.paymentHistory_tbody_merchant_uid{  
    position:relative;   
    text-align: center; 
	overflow:hidden;
    word-break:break-all;
    text-overflow:ellipsis;
    white-space : normal; /*기본값*/
    text-overflow: clip; /*기본값*/
    color: rgb(138, 138, 138);
        }  
        
        
.paymentHistory_tbody_merchant_name{   color: rgb(138, 138, 138); width: 75px;   padding-left: 0px; padding-right: 0px; text-align: center; }
.paymentHistory_tbody_amount{
    text-align: center;
    color: rgb(138, 138, 138);

}
.paymentHistory_tbody_pg{padding-right: 0px; text-align: center; color: rgb(138, 138, 138);}
.paymentHistory_tbody_paid_at{padding-right: 0px; text-align: center; color: rgb(138, 138, 138);}



    