
.CalculateAmount_main_song{
    width: 100%;
    display: flex;
    flex-direction: column;

}


.CalculateAmount_main_card {
    position: relative;
    display: block;
    flex-direction: column;
    /* background: linear-gradient( #ba414775 23%, #fff 15% ); */

    border-radius: 15px;
    box-shadow: 2px 8px 12px #1414140a, 0 1px 3px #14141405, 0 0 0 1px #00000005;
    text-align: center;
    transition: all 0.5s;
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;

    height: 450px;
}

.CalculateAmount_main_Circle{
    width: 140px;
    height: 140px;
    border-radius: 140px;
    background-color: rgb(223, 223, 223);
     display: flex;
     margin: 10px auto;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-size: 33px;
    color: rgb(39, 39, 39);
    font-weight: 600;
}


.CalculateAmount_Calculate_ProgressBar_card {
    position: relative;
    display: block;
    background: #fff 15%;
    flex-direction: row;
    border-radius: 15px;
    box-shadow: 2px 8px 12px #1414140a, 0 1px 3px #14141405, 0 0 0 1px #00000005;
    text-align: center;
    transition: all 0.5s;
    width: 99%;
    margin-right: 1%;
    /* margin-left: 20px; */
    height: 100px;
    margin-top: 40px;
    /* margin-top: 500px; */
}

.CalculateAmount_Calculate_card {
    position: relative;
    display: block;
    background: #fff 15%;
    flex-direction: row;
    border-radius: 15px;
    box-shadow: 2px 8px 12px #1414140a, 0 1px 3px #14141405, 0 0 0 1px #00000005;
    text-align: center;
    transition: all 0.5s;
    width: 99%;
    margin-right: 1%;
    /* margin-left: 20px; */
    height: 280px;
    margin-top: 20px;
}





.CalculateAmount_CalculateSong_card {
    position: relative;
    display: block;
    background: #fff 15%;
    flex-direction: row;
    border-radius: 15px;
    box-shadow: 2px 8px 12px #1414140a, 0 1px 3px #14141405, 0 0 0 1px #00000005;
    text-align: center;
    transition: all 0.5s;
    width: 100%;
    /* margin-left: 20px; */
    height: 400px;
    margin-top: 40px;
    /* margin-top: 500px; */
}

/* .Google_Chart div{
    justify-content: left;
    display: flex;
    align-items: flex-start;


} */



  
