
.BoardManagement_main_song{
  
    position: relative;
    display: flex;
    flex-direction: column;

}

.BoardManagement_main_card {
    position: relative;
    display: flex;
    /* background: linear-gradient( rgba(255, 62, 62, 0.178) 15%, #fff 15% ); */
    border-radius: 15px;
    box-shadow: 2px 8px 12px #1414140a, 0 1px 3px #14141405, 0 0 0 1px #00000005;
    text-align: center;
    transition: all 0.5s;
    width: 84%;
    height: 880px;
    margin-left: 1%;
    margin-right: 1%;
    /* margin-left: 20px; */
   
}


.BoardManagement_main_Circle{
    width: 140px;
    height: 140px;
    border-radius: 140px;
    background-color: rgb(223, 223, 223);
     display: flex;
     margin: 10px auto;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    font-size: 28px;
    color: rgb(39, 39, 39);
    font-weight: 600;
}




.BoardManagement_table{
    width: 90%;
    height: auto;
    border-collapse: collapse;
    text-align: center;
    /* margin: auto; */
    margin: 50px auto;
    justify-content: center;
    table-layout: fixed;
    cursor:default;
    outline:none;
   
}



/* ///////////////////////////////////전체 테이블///////////////////////////////////////// */


/* ///////////////////////////////////T-HEAD///////////////////////////////////////// */

.BoardManagement_table_thead_tr{
    padding-top: 20px;
    padding-bottom: 20px;
}


.BoardManagement_table_thead{
    border-bottom: 1px solid #8b8b8b98;
    border-top: 1px solid #8b8b8b98;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 60px;
}

.BoardManagement_tableHead_index{    width: 80px;}
.BoardManagement_tableHead_songname{ width: 450px;    padding-left: 0px;text-align: center;} 
/* 530 */
.BoardManagement_tableHead_genres_edit{   width: 190px; padding-left: 0px; padding-right: 0px;}
/* 740 */
.BoardManagement_tableHead_price{   width: 180px; padding-left: 0px; padding-right: 0px;  text-align: center;} 
/* 920 */
.BoardManagement_tableHead_download{   width: 110px; padding-left: 0px; text-align: center; }
/* 1030 */
.BoardManagement_tableHead_favorite{width: 90px; padding-left: 0px; padding-right: 0px; text-align: center;  }
/* 1110 */
.BoardManagement_tableHead_damgi{   width: 70px; padding-left: 0px; text-align: center; }
/* 1190 */
.BoardManagement_tableHead_rating{   width: 80px; padding-left:0px; text-align: center;}
/* 1270 */


/* ///////////////////////////////////T-BODY///////////////////////////////////////// */

.BoardManagement_tbody_table{
    border-bottom: 1px solid #97979754;
    text-align: center;
    width: 1400px;
    height: 65px;
    outline:none;
}
.BoardManagement_tbody_table :hover{background-color:rgb(241, 241, 241);  }
.BoardManagement_tbody_tr{width: 1300px; cursor: pointer; }
.BoardManagement_tbody_index{ width: 80px; font-size: 18px; font-family:Arial, Helvetica, sans-serif; font-weight: 400; color:rgb(138, 138, 138); font-style: normal;}
.BoardManagement_tbody_image{
    cursor: pointer;
    min-width: 60px;
    max-width:60px;
    min-height: 60px;
    max-height: 60px;
    object-fit: cover;
    align-items: center;
    transition: 0.3s ease;
    position: absolute;
    padding: 10px;
    margin-top: -20px;
    /* padding-bottom: ; */
    /* top: -100px; */
    /* margin-bottom: 30px; */
}
.BoardManagement_tbody_artist{  
    cursor: pointer; 
    position:relative;   
    text-align: left; 
    /* width: 360px;  */
    /* height: 90px; */
    padding-left: 10px;   
	overflow:hidden;
    word-break:break-all;
    text-overflow:ellipsis;
    white-space : normal; /*기본값*/
    text-overflow: clip; /*기본값*/
    /* text-align: center; */
        }  
        
        
.BoardManagement_songName{
    flex-direction: column;
    display: inline-block;
    text-align: center;
    justify-content: center;
    line-height: 10px;
    margin-left: 70px;
    font-size: 14px;
    /* height: 40px; */
    /* margin-bottom: 50px; */
}
.BoardManagement_artistname{
    font-size: 11px;
    color: #6e6e6e;
}

.BoardManagement_tbody_genres_edit{  opacity: 0.7;   width: 185px;  padding-left: 10px;  padding-right: 10px;  font-size: 14px; text-align: center; } 
.BoardManagement_tbody_price{
    text-align: center;
    opacity: 0.7; 
    width: 180px;

}
.BoardManagement_tbody_upgrade{   color: rgb(138, 138, 138); width: 75px;   padding-left: 0px; padding-right: 30px; text-align: center; }
.BoardManagement_tbody_favorite{   color: rgb(138, 138, 138); width: 90px; padding-right: 0px; text-align: center;}
.BoardManagement_tbody_damgi{padding-right: 0px; text-align: center; color: rgb(138, 138, 138);}
.BoardManagement_tbody_date{opacity: 0.7; font-size: 14px;  }

.BoardManagement_tbody_download_Icon{  width: 10px;  margin-top: -20px;  left:1035px;  position: absolute; }
.BoardManagement_tbody_favorite_Icon{  width: 10px;    left:1120px;  position: absolute; margin-top: -20px;;}
.BoardManagement_tbody_Look_Icon{  width: 10px;    left:0px;  position: relative; margin-top: -20px;;}
.BoardManagement__Icon{
    cursor:none;
    transform: scale(0.7);
}
  

