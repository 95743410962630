
  .PurchaseProduct_upload  {
    position: relative;
    display: flex;
    /* background: linear-gradient( #e6e6e6 14%, #fff 10% ); */
    border-radius: 15px;
    box-shadow: 2px 8px 12px #1414140a, 0 1px 3px #14141405, 0 0 0 1px #00000005;
    text-align: center;
    /* padding-top: 35px; */
    transition: all 0.5s;
    height: 880px;
    width: 84%;
    margin-left: 1%;
    margin-right: 1%;
    flex-direction: column;
}
.PurchaseProduct_product_card{
    text-align: center;
    position: relative;
    display: block;
    border-radius: 15px;
    box-shadow: 2px 8px 12px #1414140a, 0 1px 3px #14141405, 0 0 0 1px #00000005;
    /* margin: auto; */
    height: 290px;
    width: 190px;
    flex-direction: column;
    transition: all 0.5s;
    margin-left: 25px;
    margin-top: 30px;
    /* justify-content:flex-start ; */
}


.PurchaseProduct_product_card_image{  
    width:150px;
    height:150px;
    align-items: center; 
    border-radius: 20px;
    margin-top: 20px;
    z-index: 1;  
}


.PurchaseProduct_songName{
    /* margin-top: 30px; */
    /* margin: auto; */
    align-items: center;
    font-size: 15px;
    text-align: center;
    font-family:Arial, Helvetica, sans-serif;
    font-weight: 400;
    width: 150px;
    height: 40px;
    margin-left: 20px;
    /* padding-top: 35px; */
    word-break:break-all;
    text-overflow:ellipsis;
    overflow:hidden;
    /* left:40px; */
    line-height: 21px;
    display:-webkit-box;
    -webkit-line-clamp:2;
    -webkit-box-orient:vertical;
    position: absolute;
    opacity: 0.7;
}


.PurchaseProduct_buttonGroup{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 55px;
    width: 160px;
    margin-left: 15px;
}

.PurchaseProduct_button_Grid{
    width: 80px;
}
.PurchaseProduct_button_text{
    opacity: 0.9;
    font-weight: 600;
    font-size: 15px;
}
.PurchaseProduct_download_icon{
    transform: scale(1.2);
    margin-left: 8px;
}
.PurchaseProduct_download_icon :hover{
    color: rgb(209, 63, 63);
}
  

.PurchaseProduct_Dday{
    float: center;
    width: 190px;
    text-align: right;
    padding-right: 24px;
    margin-top: 8px;
    color: #888888;
    font-size: 14px;
}


@media only screen and (max-width: 850px) {
    
  
  }
/* .box11{
    background-color: rgba(255, 255, 255, 0.281);
    width: 1250px;
    
    display: flex;
    
    position: relative;
    margin:  auto;
   

   
} */

/* ///////////////////////////////////TABLE 끝 오른쪽 시작///////////////////////////////////////// */


