

@media only screen and (min-width: 1367px) {

    .contain2 {position: relative;  background-color: #FF5C5C; height: 0px; width: 1450px;  }
    .contain3 {
        position: relative;   
        display: flex;
        flex-direction: row;
    }  
    
    .contain4 { 
            position: relative;    height: auto; 
             flex-direction: row;  display: flex;   
             /* background-color: #FF5C5C;  */
             /* border-bottom: 5px solid #7979795e;    z-index: 5; */
                 }
     .Artist_Product_midiPiano {
    
        background-color: rgb(155, 155, 155);
        width: 1450px;
        height: 0px;
        position: relative;
        display: flex;
        flex-direction: column;
        min-width:1200px;
    }
    
    .Artist_Product_sheet_PDF {
        background-color: rgb(255, 255, 255);
        position: relative;
        display: block;
        /* overflow-x: hidden; */
        /* overflow-y: hidden; */
        box-shadow: 2px 8px 12px #1414140a, 0 1px 3px #14141405, 0 0 0 1px #00000005 ;

    }
    
    .Artist_Product_sheet {
        background-color: rgb(255, 255, 255);
        /* position: absolute; */
        position: relative;
        display: flex;
        flex-direction: column;
    }
    
    .youtube_main {
    
        background-color: rgb(255, 255, 255);
        /* width: 1450px; */
        height: 730px;
        position: absolute;
        display: flex;
        flex-direction: column;
        /* min-width:1200px; */
        overflow-x: auto;
        overflow-y: hidden;
    }
    
    
    
    .Artist_Product_Right_menu {
        background-color: rgb(87, 87, 87);
        width: 440px;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    
    
    .Artist_Product_Right_menu_song_name{
     text-align: center;
     font-size: 36px;
     color: rgb(255, 255, 255);
     padding-top: 10px;
     word-break:break-all;
     width: 350px;
     height: 64px;
     margin: 20px auto;
     text-overflow:ellipsis; 
     display: block;
     overflow: hidden;
    white-space: nowrap;
    font-weight: 600;
    }
    .product_song {
    
        flex-direction: column;
        width: 350px;
        height: 350px;
        align-items: center;
        display: flex;
        margin: 20px auto;
        border-radius: 300px;
        align-items: center;
        background-color: rgb(58, 58, 58);
    
       
    }
    
    .product_song__image{
    
    
        min-width: 350px;
        max-width:350px;
        min-height: 350px;
        max-height: 350px;
        object-fit: cover;
        border-radius: 350px;
        align-items: center;
       
    }
    
    
    .Artist_Product_SubBox_Info{
        background-color: rgba(218, 218, 218, 0.171);
        border-radius: 150px;
        width: 190px;
        height: 30px;
        margin-top: 10px;
    }
    
    
    
    .Artist_Product_SubBox_Info_artist{
        margin: auto;
        font-weight: 400;
        text-align: center;
        overflow: hidden;
        word-break:break-all;
        text-overflow:ellipsis; 
       white-space: nowrap;
       color: #fff;
       width: 150px;
       display: block;
    padding-top: 3px;
    
    }
    .p_con_down_icon{
        color: #fff;
        position: relative;
        width: 30px;
        transform: scale(0.7);
        /* left: 344px;
        top: 577px; */
        
    }
        .Artist_Product_right_panel{
            border-radius: 4px;
            margin: 0 auto;
            width: 90%;
            padding: 30px;
            background-color: #ffffff;
            box-shadow: 2px 8px 12px #1414140a, 0 1px 3px #14141405, 0 0 0 1px #00000005 ;
            flex-direction: column;
            display: flex;
        }





        .Artist_Product_right_panel2{
            top: 100px;
            left: 0;
            right: 0;
            position: sticky;
            /* display: block; */
            /* margin:auto; */
        }

            .Artist_Product_right_panel_grid{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap : 15px; 
                grid-row-gap : 21px;
                position: relative;
                /* margin: auto 0; */
                /* gap: 15px; */
            }

            .Artist_Product_right_panel_artistAlbum{

                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
            .Artist_Product_right_panel_songName{
                flex-direction: column;
                /* justify-content: end; */
                display: flex;
                /* font-size: 24px;
                font-weight: 600; */
            }
            .Artist_Product_right_panel_songName2{
                font-size: 24px;
                font-weight: 600;
                font-family: Arial, Helvetica, sans-serif;
                padding-top: 20px;
                padding-bottom: 20px;
                color: rgb(55, 55, 55);
                text-align: left;
                word-break:break-all;
                text-overflow:ellipsis; 
                display: block;
                overflow: hidden;
               white-space: nowrap;
               flex-direction: column;
               line-height: 50px;

               /* height: 100px; */
               /* margin: auto 0; */
               /* text-align: center; */
            }

            .Artist_Product_right_panel_favorite__Icon{
                transform: scale(0.9);
                padding-top: 0px;
                /* position: absolute; */
               
            }
            .Artist_Product_right_panel_artistAlbum_image{
    
    
                min-width: 100px;
                /* max-width:350px; */
                min-height: 100px;
                width: 100px;
                height: 100px;
                /* max-height: 350px; */
                object-fit: cover;
                align-items: center;
               
            }



.two {

  background-color: #ba414766;
}

.three {

  background-color: #ba414766;
}
.four {
  
    background-color: #ba414766;
}
.five {

    background-color: #ba414766;
}
.six {

    background-color: #ba414766;
}
        .Artist_Product_score_youtube{

            display :flex;
            flex-direction: column;
            /* height: 300px; */
            /* margin-left: 50px; */
            /* padding-left: 50px; */
            /* padding-right: 50px; */
        }
            .Artist_Product_score{
                box-shadow: 2px 8px 12px #1414140a, 0 1px 3px #14141405, 0 0 0 1px #00000005 ;
                display: flex;
                border-radius: 15px;
            }

    .Artist_Product_Bottom_menu{
        background-color: rgb(255, 255, 255);
        /* position: absolute; */
        display: flex;
        flex-direction: column;
        border-top: 1px dashed #7979795e;
        /* margin-top: 64px; */
    }
    
    .Artist_Product_Bottom_menu_mainbox{
        position: relative;
        display: flex;
        flex-direction: column;
        /* box-shadow: 2px 8px 12px #1414140a, 0 1px 3px #14141405, 0 0 0 1px #00000005 ; */
        border-radius: 15px;
        padding: 30px
        /* padding-right: 20px; */
    }
    .Artist_Product_Bottom_menu_artist_user{
        width: 15%;
        height: 100%;
        background-color: #ffffff;
        display: flex;
        border-right: 1px solid #7979795e;
    }
    
    
    .Artist_Product_Bottom_menu_buttonGroup{
        display: flex;
        position: absolute;
        margin: auto;
        padding-top: 70px;
        padding-right: 30px;
        padding-left: 25px;
        flex-direction: row;
    }
    
    
    
    
    
    .card-img-wrapper_artist  {
        position: relative;
        height: 120px;
        width: 120px;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: scale(1.4);
        cursor: pointer;
    }
     .card-img-wrapper_artist img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
      
    }
    .card-title_artist {
        color: #2b2b2b;
        font-size: 18px;
        font-weight: 600;
        line-height: 45px;
        position: relative;
        display: block;
        margin-left: 15px;
        float: left;
        cursor: pointer;
        /* margin: auto; */
        overflow: hidden;
        word-break:break-all;
        text-overflow:ellipsis; 
       white-space: nowrap;
    
    
       
    }
    
    
    
    
    
    .Artist_Product_Bottom_menu_discrpction{
        color: #6e6e6e;
        display: block;
        position: relative;
        overflow: hidden;
    
        word-break:break-all;
        word-wrap: break-word;
        text-align: left;
        white-space: normal;
        display:-webkit-box;
        -webkit-line-clamp:4;
        -webkit-box-orient:vertical;
        font-size: 15px;
        font-weight: 300;
        line-height: 24px;

        /* height: 150px; */
        margin-top: 8px;
        background-color: #ffffff;
    }
    
    .ratingsize{
        transform: scale(1.5);
        margin: auto;
        position: relative;
        display: flex;
        align-items: center;
    }
    
    
    
    
    .favorite__Icon{
        transform: scale(0.9);
        padding-top: 0px;
        position: absolute;
       
    }
    
    
    .favorite__group{
        position: relative;
        /* margin-top: 100px; */
        /* margin-left: 100px; */
        margin-left: 15px;
        display: flex;
        flex-direction: row;
    }
    
    .favorite__number{
        font-size: 15px;
        font-weight: 100;
        margin-left: 25px;
    }
    
    
    .Artist_Product_checkoutbox{  
        background-color: #fff;
        height: 90px;
        width: 190px;
        border-radius:10px;
        margin-top: 10px;
        cursor: pointer;
        display: flex;
    }
    
    .Artist_Product_checkoutbox :hover{
        background-color: #ba4148;
        border-radius:10px;
        transition: all 0.3s ease-out;
        color: #fff;
    }
    .Artist_Product_checkoutbox_text{
        text-align: center;
        font-weight: 600;
        font-size: 21px;
        width : 200px;
        line-height: 90px; 
    }
    
    
    
      
      .PlayArrowIcon{  transform: scale(1.5); }
      .__Icon{transform: scale(1.3); }
      
      .__Icon:hover{color: #fff;}
    
      .playIcon_list__optionsIcon {
        color: rgb(255, 255, 255) !important;
        cursor: pointer;
        transform: scale(1.5);
      }
}

@media screen and (max-width:1366px) and (min-width:916px) {

	}


@media screen and (max-width:915px) {


    .contain2 {background-color: #FF5C5C; height: 0px; width: 100%; }
    .contain3 {position: relative;    flex-direction: column;  display: flex;  }  
    
    .contain4 { 
            position: relative;    height: auto;  margin-top: 64px;
             flex-direction: row;  display: flex;   
             /* background-color: #FF5C5C;  */
             /* border-bottom: 5px solid #7979795e;    z-index: 5; */
                 }
     .Artist_Product_midiPiano {
    
        background-color: rgb(155, 155, 155);
        /* width: 1450px; */
        height: 0px;
        position: relative;
        display: flex;
        flex-direction: column;
        /* min-width:1200px; */
    }
    
    .Artist_Product_sheet_PDF {
        background-color: rgb(255, 255, 255);
        position: relative;
        display: block;
        /* position: absolute;
        display: flex;
        flex-direction: column;
        overflow-x: auto;
        overflow-y: hidden; */
    }
    
    
    
    .youtube_main {
    
        background-color: rgb(255, 255, 255);
        /* width: 1450px; */
        height: 730px;
        position: absolute;
        display: flex;
        flex-direction: column;
        /* min-width:1200px; */
        overflow-x: auto;
        overflow-y: hidden;
    }
    
    
    
    .Artist_Product_Right_menu {
        background-color: rgb(87, 87, 87);
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    
    
    .Artist_Product_Right_menu_song_name{
     text-align: center;
     font-size: 24px;
     color: rgb(255, 255, 255);
     word-break:break-all;
     width: 90%;
     height: 24px;
     margin: 0px auto;
     margin-top: 20px;
     text-overflow:ellipsis; 
     display: block;
     overflow: hidden;
    white-space: nowrap;
    font-weight: 600;
    flex-direction: column;
    line-height: 16px;
    }


    .Artist_Product_Right_menu_artistName{
        text-align: center;
        font-size: 15px;
        color: rgb(255, 255, 255);
        /* padding-top: 10px; */
        word-break:break-all;
        width: 90%;
        height: 21px;
        margin: 0px auto;
        text-overflow:ellipsis; 
        display: block;
        overflow: hidden;
       white-space: nowrap;
       font-weight: 600;
       flex-direction: column;
       }


    .product_song {
    
        flex-direction: column;
        width: 350px;
        height: 350px;
        align-items: center;
        display: flex;
        margin: 20px auto;
        border-radius: 300px;
        align-items: center;
        background-color: rgb(58, 58, 58);
    
       
    }
    
    .product_song__image{
    
    
        max-width:150px;
        max-height: 150px;
        object-fit: cover;
        border-radius: 150px;
        align-items: center;
        margin: auto;
        justify-content: center;
        display: flex;
    
    }
    
    
    .Artist_Product_SubBox_Info{
        border-radius: 150px;
        width: 100%;
        height: 30px;
        margin-top: 10px;
        display: block;
    }
    
    
    
    .Artist_Product_SubBox_Info_artist{
        margin: auto;
        font-weight: 400;
        text-align: center;
        overflow: hidden;
        word-break:break-all;
        text-overflow:ellipsis; 
        /* white-space:nowrap; */
       /* white-space: nowrap; */
       color: #fff;
       width: 90%;
       display: inline-block;
    padding-top: 3px;
    
    }
    .p_con_down_icon{
        color: #fff;
        position: relative;
        width: 30px;
        transform: scale(0.7);
        /* left: 344px;
        top: 577px; */
        
    }
    
    

    .Artist_Product_Bottom_menu_artist_user{
        width: 15%;
        height: 100%;
        background-color: #ffffff;
        display: flex;
        border-right: 1px solid #7979795e;
    }
    
    
    .Artist_Product_Bottom_menu_buttonGroup{
        display: flex;
        position: absolute;
        margin: auto;
        padding-top: 70px;
        padding-right: 30px;
        padding-left: 25px;
        flex-direction: row;
    }
    
    
    
    
    
    .card-img-wrapper_artist {
    
        display: flex;
        margin-top: 5px;
        margin-left: 10px;
        transform: scale(0.9);
        cursor: pointer;
    }
     .card-img-wrapper_artist img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
      
    }
    .card-title_artist {
        color: #2b2b2b;
        font-size: 18px;
        font-weight: 600;
        position: relative;
        display: block;
        float: left;
        /* background-color: #FF5C5C; */
        cursor: pointer;
        overflow: hidden;
        word-break:break-all;
        text-overflow:ellipsis; 
       white-space: nowrap;
        width: 50%;
    
       
    }
    
    
    
    
    
    .Artist_Product_Bottom_menu_discrpction{
        color: #6e6e6e;
        display: block;
        position: relative;
        overflow: hidden;
    
        word-break:break-all;
        word-wrap: break-word;
        text-align: left;
        white-space: normal;
        display:-webkit-box;
        -webkit-line-clamp:4;
        -webkit-box-orient:vertical;
        font-size: 12px;
        font-weight: 300;
        line-height: 21px;
        padding-left: 20px;
        margin-top: 4px;
        background-color: #ffffff;
    }
    
 
    
    
    
    .favorite__Icon{
        transform: scale(0.9);
        padding-top: 0px;
        position: absolute;
       
    }
    
    
    .favorite__group{
        position: relative;
        /* margin-top: 100px; */
        /* margin-left: 100px; */
        /* padding-top: 105px; */
        display: flex;
        flex-direction: row;
    }
    
    .favorite__number{
        font-size: 15px;
        font-weight: 100;
        margin-left: 25px;
    }
    
    
    .Artist_Product_checkoutbox{  
        background-color: #fff;
        height: 90px;
        width: 190px;
        border-radius:10px;
        margin-top: 10px;
        cursor: pointer;
        display: flex;
    }
    
    .Artist_Product_checkoutbox :hover{
        background-color: #ba4148;
        border-radius:10px;
        transition: all 0.3s ease-out;
        color: #fff;
    }
    .Artist_Product_checkoutbox_text{
        text-align: center;
        font-weight: 600;
        font-size: 21px;
        width : 200px;
        line-height: 90px; 
    }
    
    
    
      
      .PlayArrowIcon{  transform: scale(1.2); }
      .__Icon{transform: scale(1); }
      
      .__Icon:hover{color: #fff;}
    
      .playIcon_list__optionsIcon {
        color: rgb(255, 255, 255) !important;
        cursor: pointer;
        transform: scale(1.5);
      }

}


  
  
  

