
  .SearchResult_main_card  {
    position: relative;
    display: inline-block;
    background: linear-gradient( #ffffff 14%, #fff 10% );
    border-radius: 15px;
    box-shadow:  0 12px 13px rgba(0,0,0,0.16), 0 12px 13px rgba(0,0,0,0.16);
    text-align: center;
    transition: all 0.5s;
    height: 1020px;
    width: 100%;
    /* margin-top: 10px; */
    /* margin-left:  10px; */
}
.SearchResult_card{
    position: relative;
    display: inline-block;
    border-radius: 15px;
    box-shadow:  0 12px 13px rgba(0,0,0,0.16), 0 12px 13px rgba(0,0,0,0.16);
    margin: auto;
    height: 140px;
    width: 240px;
    transition: all 0.5s;
    margin-left: 20px;
    margin-top: 20px;
    background-color : #fff;
    outline: none;
    cursor: pointer;
}


.SearchResult_card:hover{
    background-color : #ba4148;
    z-index: 1;
}

.SearchResult_main_song_card2{
    flex-direction: row;
    width: 1850px;
    display: inline-block;
    height: 350px;
    text-align: left;
    margin-left: 10px;
}

.SearchResult_main_Artist_song_card{
    flex-direction: row;
    width: 1850px;
    display: inline-block;
    height: 180px;
    text-align: left;
    margin-left: 10px;
}


.SearchResult_main_song_card_image{  
    width:120px;
    height:120px;
    border-radius: 120px;
    display: flex;
    margin-left: 10px;
    margin-top: 15px;
}


.SearchResult_main_song_text{
    font-size: 15px;
    text-align: center;
    font-family:Arial, Helvetica, sans-serif;
    font-weight: 400;
    width: 100px;
    height: 40px;
    word-break:break-all;
    text-overflow:ellipsis;
    overflow:hidden;
    /* left:40px; */
    top: 50px;
    margin-left: 140px;
    line-height: 21px;
    display:-webkit-box;
    -webkit-line-clamp:2;
    -webkit-box-orient:vertical;
    position: absolute;
    opacity: 0.8;
}



.SearchResult_main_ReactPaginate{
    flex-direction: row;
    display: inline-block;
    text-align: center;
    margin: auto;
    position: relative;
    cursor: pointer;
    list-style: none;
    outline: none;
}

.SearchResult_main_ReactPaginate ul{flex-direction: row;     display: flex;   list-style: none; } 
.SearchResult_main_ReactPaginate li {margin-left: 20px; font-weight: 600;}
.SearchResult_main_ReactPaginate li:first-child  {font-size: 15px;}
.SearchResult_main_ReactPaginate li:last-child  {font-size: 15px;}
.SearchResult_main_ReactPaginate li:hover { color:#ec202a;}
.SearchResult_main_ReactPaginate .selected { color:#ec202a;}



@media only screen and (max-width: 850px) {
    
  
  }
