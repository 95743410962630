


@media only screen and (min-width: 1024px){

    .notice-table{
        width: 1200px;
        height: auto;
        border-collapse: collapse;
        text-align: center;
        margin: auto;
        table-layout: fixed;
        cursor:default;
        outline:none;
    }    

    .Notice_table_thead{
        border-bottom: 1px solid #8b8b8b98;
        border-top: 1px solid #8b8b8b98;
        width: 1200px;
        background-color: rgba(224, 224, 224, 0.274);
        height: 60px;
    }

    .Notice_table_tbody{
        /* height: 20px; */
        cursor: pointer;
     
    }

    .Notice_tr_table{
        border-bottom: 1px solid #8b8b8b98;
        border-top: 1px solid #8b8b8b98;
        padding-top: 10px;
        padding-bottom: 10px;
        width: 1200px;
        height: 40px;
        background-color: rgb(255, 255, 255);
    }

    .Notice_table_tbody :hover{
        background-color: rgb(238, 238, 238);
    }

    .notice_table_ReactPaginate{
        flex-direction: row;
        margin: auto;
        position: relative;
        cursor: pointer;
        list-style: none;
        outline: none;
        width: 1200px;
        display:flex;
        justify-content: center;
        margin-top: 20px;
    }
    
    .notice_table_ReactPaginate ul{flex-direction: row;     display: flex;   list-style: none; } 
    .notice_table_ReactPaginate li {margin-left: 20px; font-weight: 600;}
    .notice_table_ReactPaginate li:first-child  {font-size: 15px;}
    .notice_table_ReactPaginate li:last-child  {font-size: 15px;}
    .notice_table_ReactPaginate li:hover { color:#ec202a;}
    .notice_table_ReactPaginate .selected { color:#ec202a;}


}


@media screen and (max-width:850px) {
   
    /* .navbar__center{margin-top: 130px; position: absolute;} */
}

