
.PurchaseProduct_pagination{
    position:absolute;
        /* width: 1400px;
        height: 50px; */
        left: 40px;
        top: 830px;

}
.page-item{
    font-size:24px;
    font-weight:600;  
    display:inline-block;
    color:#383838;
    margin-Left:35px;
    cursor:pointer;
}

.page-item :hover{
    color:#ba4148;
}

