



.Artist_info_artist_img {
    position: relative;
    display: flex;
    margin-top: 5px;
    transform: scale(1.6);
}
 .Artist_info_artist_img img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
  
}



.artist__discrpction{
    height: 500px;
    color: #6e6e6e;
    display:flex;
    overflow-y: hidden;
    vertical-align:text-bottom;
    background-color: rgb(255, 255, 255);
    position: relative;
    word-break:break-all;
    text-align:center;  
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
    width: 400px;
    border: 1px dashed rgb(201, 201, 201);
    border-radius: 30px;
    /* display: flex; */
    /* margin: auto; */
   
}



.Artist_MainPage_table{
    width: 100%;
        border-collapse: collapse;
        text-align: center;
        margin-top: 20px;
        /* margin: auto; */
        table-layout: fixed;
        cursor:default;
        outline:none;
   
}


/* ///////////////////////////////////전체 테이블///////////////////////////////////////// */


/* ///////////////////////////////////T-HEAD///////////////////////////////////////// */
.Artist_MainPage_table_thead{
    width: 100%;
    height: 70px;
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid #97979754;
    border-top: 1px solid #97979754;
}

.Artist_MainPage_tableHead_index{    width: 80px;}
.Artist_MainPage_tableHead_songname{ width: 450px;    padding-left: 0px;text-align: center;} 
.Artist_MainPage_tableHead_genres_edit{   width: 190px; padding-left: 0px; padding-right: 0px;}
.Artist_MainPage_tableHead_price{   width: 180px; padding-left: 0px; padding-right:0px;  text-align: center;} 
.Artist_MainPage_tableHead_download{   width: 110px; padding-left: 0px;}
.Artist_MainPage_tableHead_favorite{width: 90px; padding-left: 0px; padding-right: 0px; }
.Artist_MainPage_tableHead_damgi{   width: 70px; padding-left: 0px;}
.Artist_MainPage_tableHead_rating{   width: 80px; padding-left:0px; text-align: center;}


/* ///////////////////////////////////T-BODY///////////////////////////////////////// */

.Artist_MainPage_tbody_table{
    border-bottom: 1px solid #97979754;
    text-align: center;
    outline:none;
    height: 80px;
}
.Artist_MainPage_tbody_table :hover{background-color:rgb(241, 241, 241);   cursor: pointer; }
.Artist_MainPage_tbody_tr{width: 1300px; }
.Artist_MainPage_tbody_index{ width: 80px; font-size: 28px; font-family:Arial, Helvetica, sans-serif; font-weight: 600; color:rgb(138, 138, 138); font-style: italic;}
.Artist_MainPage_tbody_image{
    cursor: pointer;
    min-width: 90px;
    max-width:90px;
    min-height: 90px;
    max-height: 90px;
    object-fit: cover;
    align-items: center;
    transition: 0.3s ease;
    position: relative;
    padding: 10px;
}
.Artist_MainPage_tbody_artist{ display:flex; flex-direction: row; cursor: pointer;  position:relative;    text-overflow:ellipsis;   word-break:break-all;  overflow:hidden;  text-align: left;   line-height: 24px; }   

.Artist_MainPage_tbody_songName{
    flex-direction: column;
    display: flex;
    text-align: left;
    justify-content: center;
    line-height: 21px;
    font-size: 15px;
    color: #666666;
    overflow:hidden;
    white-space : nowrap;
    text-overflow: ellipsis;
    margin-left: 10px;
}
.Artist_MainPage_tbody_artist{  
    cursor: pointer; 
    position:relative;   
    text-align: left; 
    height: 90px;
    padding-left: 20px;   
    line-height: 20px;
	overflow:hidden;
    word-break:break-all;
    text-overflow:ellipsis;
    white-space : normal; /*기본값*/
    text-overflow: clip; /*기본값*/
        }   

.Hardisest_tbody_artistname{  position: relative;  font-size: 10px;}
.Artist_MainPage_tbody_genres_edit{  color: rgb(138, 138, 138);  width: 185px;  padding-left: 0px;  padding-right: 10px;  font-size: 15px; text-align: center; } 
.Artist_MainPage_tbody_price{
    text-align: center;
    color: rgb(138, 138, 138);
    width: 180px;
    padding-left: 0px;
    padding-right: 0px;
}
.Artist_MainPage_tbody_upgrade{   color: rgb(138, 138, 138); width: 75px;   padding-left: 0px; padding-right: 0px; text-align: center; }
.Artist_MainPage_tbody_favorite{   color: rgb(138, 138, 138); width: 90px; padding-right: 0px; text-align: center;}
.Artist_MainPage_tbody_rating{
    font-size: 9px;
    position: relative;
    color: #b3b3b3;
    justify-content: center;
    align-items: center;

}
.Artist_MainPage_tbody_damgi{transform: scale(1); align-items: center; justify-content: center; }

.Artist_MainPage_tbody_rating_star{ justify-content: center;
    align-items: center;}
.Artist_MainPage_tbody_rating_star_icon{ padding-top: 0px;  margin-top:20px; display:flex; justify-content: center; margin-left: 0px; padding-right: 10px; }

.Artist_MainPage_tbody_view{ text-align: center;
    color: rgb(138, 138, 138);}

.Artist_MainPage_tbody_add_Icon{transform: scale(1); color: #6e6e6e;}  
.Artist_MainPage_tbody_download_Icon{  width: 10px;    left:960px;  position: absolute; margin-top: 36px;}
.Artist_MainPage_tbody_favorite_Icon{  width: 10px;    left:1055px;  position: absolute; margin-top: 36px;}

    .Artist_MainPage_basket_menu {
        position: relative;
        flex-direction: row;
        display: flex;
        justify-content: center;
    }
     .Artist_MainPage_basket_menu li {
        list-style-type: none
    }

    .Artist_MainPage_basket_menu_icon :link, 
    .Artist_MainPage_basket_menu_icon :visited { z-index:100; display:block; padding:0 22px; line-height:64px; color:rgb(255, 255, 255); font-weight:500; letter-spacing:-0.5pt;   font-family: Arial, Helvetica, sans-serif;  font-weight:500;   transition: all 0.2s ease;}
    .Artist_MainPage_basket_menu_icon :hover{color:#000; text-decoration:none;  }

    .Artist_MainPage_basket_menu div {visibility:hidden; position:absolute; z-index:100; }
    .Artist_MainPage_basket_menu li:hover>div { visibility:visible;    transition: transform 0.6s cubic-bezier(0.83, 0, 0.17, 1);  transform: translate3d(0, 15%, 0);}  
    .Artist_MainPage_basket_menu li li { color:#666; letter-spacing:-0.5pt; padding:3px 15px; text-align:left; font-size:14px; white-space:normal; }
    .Artist_MainPage_basket_menu li li :hover{ color:#ba4148; background:rgb(255, 255, 255); cursor: pointer;}
    .Artist_MainPage_basket_menu li li:first-child {padding-top:20px;}
    .Artist_MainPage_basket_menu li li:last-child  {padding-bottom:20px;}
   
   
    .Artist_MainPage_basket_menu_depth {visibility:hidden; position:absolute; width:150px; }
    .Artist_MainPage_basket_menu_depth ul {margin-top:-20px; box-shadow:0px 0px 10px rgba(102,102,102,0.2); background:rgb(255, 255, 255); box-sizing:border-box; z-index:100 !important; border-radius:5px;}
    .Artist_MainPage_basket_menu_depth li:hover {background:rgb(255, 255, 255);  color:#666; letter-spacing:-0.5pt; text-align:left; font-size:14px; white-space:normal;}

