.Checkout_Result_mainCircle{


    display:flex;
    margin: auto;
    border-color: aliceblue;

}
.Checkout_Result_CheckCircleOutline__Icon{
    transform: scale(10);
    color:#ba4148;
    position: relative;
    margin-top: 70px;
    ;}