
@media only screen and (min-width: 1367px) {
.Hardisestslider {
	width: 80%;
	background-color: rgb(216, 216, 216);
	height: 250px;
	margin: auto;
	margin-top: 40px;
}


.Hardisestslider_text {
	height: 60px;
	width: 50%;
	text-align: right;
	left: 48%;
	top: 58%;
	/* margin-left: 1200px; */
	z-index: 300;
	position: absolute;
	font-size: 36px;
	line-height: 32px;
	overflow: hidden;
	word-break: break-all;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.Hardisestslider_text2 {
	width: 50%;
	height: 45px;
	text-align: right;
	left: 48%;
	top: 75%;
	padding-top: 10px;
	/* margin-left: 1200px; */
	z-index: 300;
	position: absolute;
	font-size: 24px;
	line-height: 21px;
	overflow: hidden;
	word-break: break-all;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.Hardisestslider__image24 {
	width: 1500px;
	height: 250px;
	object-fit: cover;
	z-index: 1 !important;
	;
	opacity: 0.2 !important;
	margin: auto;
	filter: blur(1px);
	-webkit-filter: blur(1px);
	-o-filter: blur(1px);
	-ms-filter: blur(1px);

}


.Hardisestslider__image25 {
	width: 250px;
	height: 250px;
	object-fit: cover;
	z-index: 1 !important;
	;
	opacity: 1 !important;

	position: absolute;
	margin-left: 30px;
}

.Hardisestslider-image_opacity1 {
	position: absolute;
}



.slick-slide.slick-center img {
	opacity: 1 !important;
	z-index: 1 !important;
}

.slick-slide {
	position: relative;
	z-index: 2;
}
}


@media screen and (max-width:1366px) and (min-width:916px) {
	.Hardisestslider {
		width: 90%;
		height: 250px;	
		margin: auto;
		margin-top: 40px;
	}
	
	
	.Hardisestslider_text {
		height: 60px;
		width: 50%;
		text-align: right;
		left: 48%;
		top: 52%;
		z-index: 300;
		position: absolute;
		font-size: 38px;
		line-height: 32px;
		overflow: hidden;
		word-break: break-all;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	
	.Hardisestslider_text2 {
		width: 50%;
		height: 45px;
		text-align: right;
		left: 48%;
		top: 70%;
		padding-top: 10px;
		z-index: 300;
		position: absolute;
		font-size: 26px;
		line-height: 21px;
		overflow: hidden;
		word-break: break-all;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	
	.Hardisestslider__image24 {
		width: 100%;
		height: 250px;
		background-color: rgb(216, 216, 216);
		object-fit: cover;
		z-index: 1 !important;
		;
		opacity: 0.2 !important;
		margin: auto;
		filter: blur(1px);
		-webkit-filter: blur(1px);
		-o-filter: blur(1px);
		-ms-filter: blur(1px);
	
	}
	.Hardisestslider__image25 {
		width: 250px;
		height: 250px;
		object-fit: cover;
		z-index: 1 !important;
		opacity: 1 !important;
		position: absolute;
		margin-left: 30px;
	}
	
	.Hardisestslider-image_opacity1 {
		position: absolute;
	}
	.slick-slide.slick-center img {
		opacity: 1 !important;
		z-index: 1 !important;
	}	
	.slick-slide {
		position: relative;
		z-index: 2;
	}
	}
	
	
@media screen and (max-width:915px) {
	.Hardisestslider {
		width: 90%;
		height: 200px;	
		margin: auto;
		margin-top: 40px;
	}
	
	
	.Hardisestslider_text {
		height: 60px;
		width: 40%;
		text-align: right;
		left: 56%;
		top: 52%;
		z-index: 300;
		position: absolute;
		font-size: 32px;
		line-height: 32px;
		overflow: hidden;
		word-break: break-all;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	
	.Hardisestslider_text2 {
		width: 40%;
		height: 45px;
		text-align: right;
		left: 56%;
		top: 70%;
		padding-top: 10px;
		z-index: 300;
		position: absolute;
		font-size: 21px;
		line-height: 21px;
		overflow: hidden;
		word-break: break-all;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	
	.Hardisestslider__image24 {
		width: 100%;
		height: 200px;
		background-color: rgb(216, 216, 216);
		object-fit: cover;
		z-index: 1 !important;
		;
		opacity: 0.2 !important;
		margin: auto;
		filter: blur(1px);
		-webkit-filter: blur(1px);
		-o-filter: blur(1px);
		-ms-filter: blur(1px);
	
	}
	.Hardisestslider__image25 {
		width: 200px;
		height: 200px;
		object-fit: cover;
		z-index: 1 !important;
		opacity: 1 !important;
		position: absolute;
		
	}
	
	.Hardisestslider-image_opacity1 {
		position: absolute;
	}
	.slick-slide.slick-center img {
		opacity: 1 !important;
		z-index: 1 !important;
	}	
	.slick-slide {
		position: relative;
		z-index: 2;
	}
	}